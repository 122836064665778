<template>
    <div
        class="modale-container"
    >
      <div class="modale-overlay"></div>
      <div
          class="modale-item-container"
          v-for="(castle, index) in data"
          :key="index"
      >
        <div class="close-modale-container">
          <button class="close-modale-btn" @click="hideModal">
            <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
              <g id="Groupe_1259" data-name="Groupe 1259" transform="translate(-430 -162)">
                <path id="Tracé_704" data-name="Tracé 704" d="M30,0A30,30,0,1,1,0,30,30,30,0,0,1,30,0Z" transform="translate(430 162)" fill="#262421"/>
                <g id="add-circle--button-remove-cross-add-buttons-plus-circle-_-mathematics-math" data-name="add-circle--button-remove-cross-add-buttons-plus-circle-+-mathematics-math" transform="translate(459.846 165.683) rotate(45)">
                  <path id="Vector_2" d="M7,4V21.692" transform="translate(11.5 5.654)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                  <path id="Vector_3" d="M4,7H21.692" transform="translate(5.654 11.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                </g>
              </g>
            </svg>
          </button>
        </div>
        <div class="modale-content-map-container">
          <div class="modale-img-content-container">
            <div class="modale-img-container">
              <img :src="image" alt="Château de Melin">
            </div>
            <div class="modale-content-container">
              <h1>{{ castle.title[languageStore.lang] }}</h1>
              <p v-html="castle.description[languageStore.lang]"></p>
            </div>
          </div>
          <div class="modale-map-container">
            <span class="title-map">{{ castle.titleMap[languageStore.lang] }}</span>
            <img :src="map" alt="Map">
          </div>
        </div>

      </div>

    </div>
</template>

<script setup>

import {castleStore} from "@/stores/castleStore";
import {languageStore} from "@/stores/languageStore";
import castleData from '@/data/castle/castle.json';
import image from '@/assets/img/castle/chateau.jpg';
import map from '@/assets/img/castle/map.jpg'

const data = castleData;

const hideModal = () => {
  castleStore.setShowCastleItem(false);
}

</script>

<style scoped>

.modale-container {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
}

.close-modale-container {
  position: absolute;
  right: 25px;
  top: 25px;
}

.close-modale-container .close-modale-btn {
  background: transparent;
  border: none;
}

.modale-img-container img {
  border-radius: 3px 0 0 0;
  object-fit: cover;
  width: 548px;
  height: 100%;
}

h1 {
  margin: 10px 0;
}

.modale-content-container p {
  color: #8F8D88;
  column-count: 2;
  padding: 20px 90px;
  margin: auto;
}

.modale-overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #F6F5F2;
  width: 100%;
}

.modale-item-container {
  position: absolute;
  z-index: 10;
  top: 50px;
  left: 25px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  width: 1860px;
  margin: auto;
}

.modale-img-content-container {
  display: flex;
}

.modale-img-container {
  width: 550px;
}

.modale-content-container {
  width: calc(100% - 55px);
}

.modale-content-map-container {
  border-radius: 0 0 3px 3px;
}

.modale-map-container {
  position: relative;
  background: #F7F8F3;
  border-radius: 0 0 3px 3px;
}

.modale-map-container .title-map {
  position: absolute;
  top: 15px;
  left: 0;
  right: 0;
  font-family: var(--secondary-font);
  font-size: 52px;
  text-align: center;
}

</style>