<template>

  <div class="vinification-title-container">
    <h1>{{ props.title }}</h1>
  </div>

</template>

<script setup>

import { defineProps } from 'vue';

const props = defineProps({
  title: String
})

</script>

<style scoped>

</style>