<template>
  <div class="home-pages-container">
    <PageItem :page="page" v-for="(page, index) in props.pageList" :key="index"/>
  </div>
</template>

<script setup>

import PageItem from "@/components/pages/PageItem.vue";
import { defineProps } from 'vue';

const props = defineProps({
  pageList: Array
});

</script>

<style scoped>
.home-pages-container {
  display: flex;
  justify-content: center;
  gap: 10px;
}
</style>
