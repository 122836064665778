<template>

  <div class="vinification-sidebar-container">
      <VinificationStep :wineStep="wineStep" v-for="(wineStep, index) in props.wineStepList" :key="index" :isSelected="index === currentIndex" @click="setCurrentIndex(index)"/>
  </div>

</template>

<script setup>

import VinificationStep from "@/components/vinification-timeline/VinificationStep.vue";
import {defineProps} from "vue";

const props = defineProps({
  wineStepList: Array,
  currentIndex: Number,
  setCurrentIndex: Function
});

</script>

<style scoped>

.vinification-sidebar-container {
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  column-gap: 18px;
  row-gap: 8px;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 0 15px rgba(0, 0, 0, .1);
  width: 175px;
  height: 100%;
  padding: 15px 35px 30px 15px;
  margin-top: -80px;
}

</style>