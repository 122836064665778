<template>
  <div class="page-item-container">
    <a :href="props.page.url" class="page-main-link">
      <div class="page-item-img-container">
        <img :src="props.page.img ? getImageSrc(props.page.img) : ''" :alt="props.page.title[languageStore.lang]">
      </div>
      <div class="page-item-content-container">
        <div class="page-item-title-container">
          <h2 class="page-item-title">{{ props.page.title[languageStore.lang] }}</h2>
        </div>
<!--        <div class="page-link-container">-->
<!--          <a :href="props.page.url" class="page-link">{{ props.page.btnText[languageStore.lang] }}</a>-->
<!--        </div>-->
      </div>
    </a>
  </div>
</template>

<script setup>

import {defineProps} from "vue";
import {languageStore} from "@/stores/languageStore";

const props = defineProps({
  page: Object
});

const getImageSrc = (image) => {
  if (image) {
    return require(`@/assets/img/home/${image}`);
  } else {
    return '';
  }
};

</script>

<style scoped>

/*Containers*/

.page-item-container {
  position: relative;
}

.page-item-content-container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/*Title*/

.page-item-title {
  font-size: 40px;
  font-weight: 400;
  color: #fff;
}

</style>