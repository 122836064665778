<template>

  <div class="vinification-step-card-list">
    <swiper
        :modules="[Navigation]"
        :slides-per-view="1"
        :initialSlide="sliderCurrentIndex"
        @swiper="onSwiper"
        @activeIndexChange="activeIndexChange"
        :navigation="{ prevEl: '.custom-prev-button', nextEl: '.custom-next-button' }"
        :pagination="{ clickable: true }"
        class="vinification-step-card-items"
    >
      <swiper-slide class="item-container" :class="['swiper-item-' + index]" v-for="(wineStep, index) in props.wineStepList" :key="index">
        <div class="item-container-slide">
          <VinificationStepCard :wineStep="wineStep"/>
        </div>
      </swiper-slide>
    </swiper>
    <div class="swiper-buttons-container">
      <button class="custom-prev-button custom-button">
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 320 512"><path d="M-1.9 256l17-17L207 47l17-17L257.9 64 241 81 65.9 256 241 431l17 17L224 481.9l-17-17L15 273l-17-17z"/></svg>
      </button>
      <button class="custom-next-button custom-button">
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 320 512"><path d="M305 239c9.4 9.4 9.4 24.6 0 33.9L113 465c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l175-175L79 81c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L305 239z" fill="currentColor"/></svg>
      </button>
    </div>
  </div>

</template>

<script setup>
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/css';
import VinificationStepCard from "@/components/vinification-timeline/VinificationStepCard.vue";
import {defineProps, computed, watch} from 'vue';
import {Navigation} from "swiper/modules";

const props = defineProps({
  wineStepList: Array,
  currentIndex: Number,
  setCurrentIndex: Function
});

let swiperInstance = null;
const sliderCurrentIndex = computed(() => props.currentIndex);

const onSwiper = (swiper) => {
  swiperInstance = swiper;
};

const activeIndexChange = (param) => {
  props.setCurrentIndex(param.activeIndex);
};

watch(sliderCurrentIndex, (newIndex) => {
  swiperInstance.slideTo(newIndex);
});

</script>

<style scoped>

/*Main container*/

.vinification-step-card-items {
  display: flex;
  column-gap: 65px;
  //width: 1500px;
}

/*Single item*/


.item-container-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  height: 600px;
  margin: 25px;
}

.swiper-slide-active .item-container-slide {
  box-shadow: 0 0 15px rgba(0, 0, 0, .1);
}

.white .swiper-item-6,
.white .swiper-item-7,
.red .swiper-item-11,
.red .swiper-item-12,
.red .swiper-item-13,
.red .swiper-item-14 {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.swiper-buttons-container {
  position: absolute;
  left: 50%;
  bottom: 15px;
  display: flex;
  gap: 15px;
  width: 100%;
}

.custom-button {
  background: transparent;
  color: #000;
  border: none;
  cursor: pointer;
}

</style>