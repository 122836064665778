<template>
  <div class="wines-list-container">
    <div
        class="wine-item chateau"
        @click="toggleModal"
    >
      <div class="wine-item-chateau-title-icon-container">
        <img :src="filigrane" alt="" class="btn-img">
        <span v-html="getBtnText()[languageStore.lang]"></span>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
        <g id="Groupe_1331" data-name="Groupe 1331" transform="translate(-471 -154)">
          <circle id="Ellipse_56" data-name="Ellipse 56" cx="14" cy="14" r="14" transform="translate(471 154)" fill="#fff"/>
          <g id="Groupe_1330" data-name="Groupe 1330" transform="translate(1 -137.833)">
            <line id="Ligne_423" data-name="Ligne 423" y1="3.667" x2="3.667" transform="translate(487 309.5) rotate(180)" fill="none" stroke="#a2a09b" stroke-linecap="round" stroke-width="1.5"/>
            <line id="Ligne_424" data-name="Ligne 424" y1="3.667" x2="3.667" transform="translate(483.333 305.833) rotate(-90)" fill="none" stroke="#a2a09b" stroke-linecap="round" stroke-width="1.5"/>
          </g>
        </g>
      </svg>
    </div>
<!--    <WinesToggle/>-->
    <WineItem type="white" :title="getTitleTranslations().white[languageStore.lang]" :wines="wines.white"/>
    <WineItem type="red" :title="getTitleTranslations().red[languageStore.lang]" :wines="wines.red"/>

  </div>
</template>

<script setup>

import WineItem from "@/components/wines/WineItem.vue";
// import WinesToggle from "@/components/wines/WinesToggle.vue";
import {languageStore} from "@/stores/languageStore";
import filigrane from "@/assets/img/castle/hr.png";
import {castleStore} from "@/stores/castleStore";
import {ref} from "vue";
import axios from "axios";

const getTitleTranslations = () => {
  return {
    "white": {
      "french": "Vins blancs",
      "english": "White wines"
    },
    "red": {
      "french": "Vins rouges",
      "english": "Red wines"
    }
  };
};

const getBtnText = () => {
  return {
      "french": "Découvrez le Château de Melin",
      "english": "Discover Château de Melin"
  };
};

const wines = ref({
  red:[],
  white: []
});

const fetchData = async () => {
  try {
    const response = await axios.get('https://chateaudemelin.com/wp-json/bwa/wines-v2');

    const formattedData = {
      white: response.data.white.map((wine) => {
        return {
          ...wine,
          id:`wine-${wine.id}`
        }
      }),
      red: response.data.red.map((wine) => {
        return {
          ...wine,
          id:`wine-${wine.id}`
        }
      })
    }
    wines.value = formattedData;
  } catch (error) {
    console.error('Error fetching wines:', error);
  }
};

fetchData();

const toggleModal = () => {
  castleStore.toggleShowCastleItem();
}

</script>

<style scoped>

.wines-list-container {
  position: relative;
  z-index: 1;
  background-color: #fff;
  border-radius: 3px;
  width: 400px;
  padding: 30px 65px;
  margin: 30px 0 0 30px;
}

.wine-item-chateau-title-icon-container {
  display: flex;
  gap: 10px;
}

.wine-item.chateau {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  font-size: 20px;
  background: var(--secondary-color);
  color: #A2A09B;
  border-top: none;
  border-radius: 5px;
  height: 60px;
  padding: 0 15px;
  margin-bottom: 1em;
}

</style>