import { createRouter, createWebHistory } from 'vue-router'

import AppLayout from '@/components/AppLayout.vue'
import HomePage from '@/views/HomePage.vue'
import WinesPage from '@/views/WinesPage.vue'
import VinificationPage from '@/views/VinificationPage.vue'
import VinificationTimelinePage from '@/views/VinificationTimelinePage.vue'
import CyclePage from '@/views/CyclePage.vue'
import BioPage from "@/views/BioPage.vue";

const routes = [
	{
		path: '/',
		component: AppLayout,
		children: [
			{
				path: '/',
				name: 'HomePage',
				component: HomePage
			},
			{
				path: '/nos-vins',
				name: 'WinesPage',
				component: WinesPage
			},
			{
				path: '/vinification',
				name: 'VinificationPage',
				component: VinificationPage
			},
			{
				path: '/vinification-timeline/:type',
				name: 'VinificationTimelinePage',
				component: VinificationTimelinePage
			},
			{
				path: '/cycle-vigne',
				name: 'CyclePage',
				component: CyclePage
			},
			{
				path: '/agriculture-biologique',
				name: 'BioPage',
				component: BioPage
			},
		]
	},
]

export default createRouter({
	history: createWebHistory(),
	routes
});
