<template>
  <div v-if="props.wineStep.image && getTitle" :class="['vinification-step-item', { 'vinification-step-item--is-selected': isSelected }]">
    <div class="vinification-step-img-container">
      <img :src="getImageSrc(props.wineStep.image)" :alt="props.wineStep.title[languageStore.lang]" class="vinification-step-img">
    </div>
    <div class="vinification-step-title-container">
      <span class="vinification-step-title" v-html="getTitle"></span>
    </div>
  </div>
</template>

<script setup>

import {defineProps, computed} from "vue";
import {languageStore} from "@/stores/languageStore";

const props = defineProps({
  wineStep: Object,
  isSelected: Boolean
});

const getTitle = computed(() => {
  return props.wineStep.sidebarTitle && props.wineStep.sidebarTitle[languageStore.lang] ? props.wineStep.sidebarTitle[languageStore.lang] : props.wineStep.title[languageStore.lang];
});

const getImageSrc = (image) => {
  return image ? require(`@/assets/img/steps/${image}`) : '';
}
</script>


<style scoped>

.vinification-step-item {
  display: flex;
  align-items: center;
  column-gap: 18px;
}

.vinification-step-item .vinification-step-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 55px;
}

.vinification-step-item--is-selected .vinification-step-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.vinification-step-item--is-selected .vinification-step-img-container img {
  border: 2px solid #000;
  border-radius: 50%;
  padding: 5px;
}

.vinification-step-title-container {
  width: 90px;
}

.vinification-step-title {
  font-size: 13px;
  color: #827C70;
}

.vinification-step-item--is-selected .vinification-step-title {
  font-weight: 600;
}

</style>