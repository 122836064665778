<template>
  <div v-no-right-click>
    <router-view />
  </div>
</template>

<script setup>

import { onMounted, onUnmounted } from 'vue';

const disablePinchZoom = (event) => {
  event.preventDefault();
};

onMounted(() => {
  document.addEventListener('gesturestart', disablePinchZoom);
});

onUnmounted(() => {
  document.removeEventListener('gesturestart', disablePinchZoom);
});

</script>

<style>

@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');

body {
  font-family: var(--primary-font);
}

</style>
