export default {
	mounted(el) {
		el.addEventListener('contextmenu', (event) => {
			event.preventDefault();
		});
	},
	unmounted(el) {
		el.removeEventListener('contextmenu', (event) => {
			event.preventDefault();
		});
	},
};