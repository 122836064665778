<template>
  <div class="items-container">
    <div class="item-container" v-for="item in items" :key="item.title">
      <router-link :to="'/vinification-timeline/' + item.type" class="item-link page-main-link">
      <div class="item-item-img-container">
        <img :src="item.img" :alt="item.title">
      </div>
      <div class="item-item-content-container">
        <div class="item-title-container">
          <h2 class="item-item-title">{{item.title[languageStore.lang]}}</h2>
        </div>
        <div class="item-description-container">
          <p class="item-description">{{item.description[languageStore.lang]}}</p>
        </div>
        <div class="item-link-container">
          <router-link :to="'/vinification-timeline/' + item.type" class="item-link page-link">
            {{item.btnText[languageStore.lang]}}
          </router-link>
        </div>
      </div>
      </router-link>
    </div>
  </div>
</template>

<script setup>
import {languageStore} from "@/stores/languageStore";

const items = [
  {
    title: {
      "french" : 'Vins Blancs',
      "english" : 'White Wines'
    },
    description: {
      "french" :
          'La vinification des vins blancs est sensiblement différente de celle des vins rouges. Contrairement à ce qui est fait en rouge, les vinificateurs ne souhaitent pas extraire les éléments de la peau des raisins. Les baies sont donc directement pressées, sans avoir été égrappées la pluparts du temps.\n' +
          'Le jus sera ensuite mis en cuves ou en fûts de chêne, où il effectuera sa fermentation alcoolique puis, une seconde fermentation, dite malolactique. Celle-ci est caractéristique de la vinification des vins blancs de Bourgogne par rapport aux autres vins blancs français. Elle apporte notamment de la rondeur aux vins.',
      "english" :
          'The vinification of white wines is significantly different from that of red wines. Unlike what is done in red, winemakers do not wish to extract elements from the skins of the grapes. The berries are therefore directly pressed, without having been destemmed most of the time.\n' +
          'The juice will then be put into vats or oak barrels, where it will carry out its alcoholic fermentation then a second fermentation, called malolactic. This is characteristic of the vinification of Burgundy white wines compared to other French white wines. It notably brings roundness to the wines.'
    },
    img: require('@/assets/img/vinification/vins-blancs.jpg'),
    type: "white",
    btnText: {
      "french" : "Découvrir",
      "english" : "Discover"
    }
  },
  {
    title: {
      "french" : 'Vins Rouges',
      "english" : 'Red Wines'
    },
    description: {
      'french':
          'Du nord au sud de la Bourgogne, suivant les caractéristiques du millésime, la vinification en rouge comporte des approches sensiblement différentes pour certaines étapes. Par exemple, certains vignerons égrappent alors que d’autres vinifient « en vendange entière ». Le cépage joue aussi. Le Pinot Noir, particulièrement délicat, nécessite plus que tout autre raisin des gestes précis et mesurés.',
      'english' :
          'From the north to the south of Burgundy, depending on the characteristics of the vintage, red winemaking involves significantly different approaches for certain stages. For example, some winegrowers destemm while others vinify “whole harvest”. The grape variety also plays a role. Pinot Noir, particularly delicate, requires precise and measured gestures more than any other grape.'
    },
    img: require('@/assets/img/vinification/vins-rouges.jpg'),
    type: "red",
    btnText: {
      "french" : "Découvrir",
      "english" : "Discover"
    }
  }
];
</script>


<style scoped>

/*Containers*/

.items-container {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.item-container {
  background: #100F0E;
}

/*Content*/

.item-item-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 360px;
}

.item-item-content-container .item-title-container {
  padding-top: 2em;
}

.item-item-content-container .item-item-title {
  font-size: 28px;
  font-weight: 300;
  color: #fff;
  margin: 0;
}

.item-description-container {
  display: flex;
  align-items: center;
  width: 650px;
  height: 180px;
  padding: 15px 0;
}

.item-description {
  color: #8F8D88;
  text-align: center;
  margin: 0;
}

.item-link-container {
  margin-bottom: 10px;
}

</style>