import { reactive } from 'vue';

export const castleStore = reactive({
	showCastleItem: false,
	toggleShowCastleItem() {
		this.showCastleItem = !this.showCastleItem;
	},
	setShowCastleItem(isVisible) {
		this.showCastleItem = isVisible;
	}
});