<template>
  <div class="container-inner">
    <CycleWheel :currentSlideIndex="currentSlideIndex"
                :isSmaller="isDisplayedDetailCard"
    />
    <TitlePage :title="getTitlePage()[languageStore.lang]" v-if="!isDisplayedDetailCard"/>
    <CycleMonthList
        :monthList="monthData"
        @slide-changed="handleSlideChange"
        @button-click="handleButtonClick"
        :isHidden="isDisplayedDetailCard"
        :cycleMonthSwiperRef="cycleMonthSwiperRef"
        :setCycleMonthSwiperRef="setCycleMonthSwiperRef"
    />
    <Transition>
      <CycleMonthDetailCard
          v-if="isDisplayedDetailCard"
          :detailCard="monthData[currentSlideIndex].cardList[currentSelectedCardIndex].detailCardList[currentDetailCardIndex]"
          @close-button-click="handleCloseBtnClick"
      />
    </Transition>
  </div>
</template>

<script setup>

import TitlePage from "@/components/elements/TitlePage.vue";
import CycleWheel from "@/components/cycle/CycleWheel.vue";
import CycleMonthList from "@/components/cycle/CycleMonthList.vue";
import monthData from '@/data/cycle-months/cycle-vigne.json';
import {ref} from "vue";
import {languageStore} from "@/stores/languageStore";
import CycleMonthDetailCard from "@/components/cycle/CycleMonthDetailCard.vue";

const currentDate = new Date();
const currentMonthIndex = currentDate.getMonth();

const isDisplayedDetailCard = ref(false);
const currentSlideIndex = ref(currentMonthIndex);
const currentSelectedCardIndex = ref(0);

const currentDetailCardIndex = ref(0);

const cycleMonthSwiperRef = ref(null);

const setCycleMonthSwiperRef = (newCycleMonthSwiperRef) => {
  cycleMonthSwiperRef.value = newCycleMonthSwiperRef;
}

const handleSlideChange = (newIndex) => {
  currentSlideIndex.value = newIndex;
};
const handleButtonClick = (data) => {
  setIsDisplayedDetailCard(true);
  currentDetailCardIndex.value = data.buttonIndex;
  currentSelectedCardIndex.value = data.monthIndex;
};


const getTitlePage = () => {
  return {
    "french": "Cycle de la vigne",
    "english": "Cycle of the vines"
  };
};

const setIsDisplayedDetailCard = (displayDetailCard) => {
  isDisplayedDetailCard.value = displayDetailCard;
};

const handleCloseBtnClick = () => {
  setIsDisplayedDetailCard(false);
}

</script>

<style scoped>

.container-inner {
  background-image: url(../assets/img/cycle/filigrane-cycle.png);
  background-repeat: no-repeat;
  background-position: 100% 13%;
  overflow: hidden;
}

.vinification-title-container {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
}

</style>