<template>

  <div class="wheel-svg-container"
       :class="{'wheel-svg-container--is-smaller': isSmaller}"
  >
    <div class="wheel-circle-container">
      <span class="wheel-circle"></span>
    </div>
    <svg :class="['wheel', {'wheel--no-transition': cancelTransition}]" xmlns="http://www.w3.org/2000/svg" width="1320" height="1320" viewBox="0 0 1478.88 1477.212">

      <g id="Calque_1_1_">
        <g id="Groupe_1329" transform="translate(-5445 1093.212)">
          <g id="Groupe_1328" transform="translate(-0.402)">
            <text transform="matrix(6.123234e-17 -1 1 6.123234e-17 6912.7593 -325.2265)" class="st0 st1 juin">{{ monthData[5].month[languageStore.lang].toUpperCase() }}</text>
            <text transform="matrix(6.123234e-17 1 -1 6.123234e-17 5455.8804 -425.6617)" class="st0 st1 decembre">{{ monthData[11].month[languageStore.lang].toUpperCase() }}</text>
          </g>
          <g id="Ellipse_47" transform="translate(5525 -1016)">
            <circle class="st2" cx="659.5" cy="662" r="660"/>
            <circle class="st3" cx="659.5" cy="662" r="659.5"/>
          </g>
          <text transform="matrix(1 0 0 1 6135.4961 377.9833)" class="st0 st4 mars">{{ monthData[2].month[languageStore.lang].toUpperCase() }}</text>
          <text transform="matrix(0.866 -0.5 0.5 0.866 6515.0771 291.9227)" class="st0 st1 avril">{{ monthData[3].month[languageStore.lang].toUpperCase() }}</text>
          <text transform="matrix(0.5 -0.866 0.866 0.5 6801.9907 28.3406)" class="st0 st1 mai">{{ monthData[4].month[languageStore.lang].toUpperCase() }}</text>
          <text transform="matrix(-0.5 -0.866 0.866 -0.5 6832.5483 -678.4387)" class="st0 st1 juillet">{{ monthData[6].month[languageStore.lang].toUpperCase() }}</text>
          <text transform="matrix(-0.866 -0.5 0.5 -0.866 6578.0684 -956.7341)" class="st0 st1 aout">{{ monthData[7].month[languageStore.lang].toUpperCase() }}</text>
          <text transform="matrix(-1 0 0 -1 6261.6133 -1080.2284)" class="st0 st1 septembre">{{ monthData[8].month[languageStore.lang].toUpperCase() }}</text>
          <text transform="matrix(-0.866 0.5 -0.5 -0.866 5867.3213 -1007.9509)" class="st0 st1 octobre">{{ monthData[9].month[languageStore.lang].toUpperCase() }}</text>
          <text transform="matrix(-0.5 0.866 -0.866 -0.5 5599.6758 -783.8659)" class="st0 st1 novembre">{{ monthData[10].month[languageStore.lang].toUpperCase() }}</text>
          <text transform="matrix(0.866 0.5 -0.5 0.866 5775.6392 244.172)" class="st0 st1 fevrier">{{ monthData[1].month[languageStore.lang].toUpperCase() }}</text>
          <text transform="matrix(0.5 0.866 -0.866 0.5 5526.1123 -41.8718)" class="st0 st1 janvier">{{ monthData[0].month[languageStore.lang].toUpperCase() }}</text>
          <g id="Groupe_1326" transform="translate(-2.5)">
            <line id="Ligne_405" class="st5" x1="6187.5" y1="277.5" x2="6187.5" y2="-353.5"/>
            <line id="Ligne_409" class="st5" x1="5857" y1="217.8" x2="6187" y2="-353.8"/>
            <line id="Ligne_410" class="st5" x1="6517" y1="217.8" x2="6187" y2="-353.8"/>
            <line id="Ligne_411" class="st5" x1="6758.6" y1="-23.5" x2="6187" y2="-353.5"/>
            <line id="Ligne_412" class="st5" x1="5615.4" y1="-23.5" x2="6187" y2="-353.5"/>
            <line id="Ligne_405-2" class="st5" x1="6187.5" y1="-1011.5" x2="6187.5" y2="-354.3"/>
            <line id="Ligne_409-2" class="st5" x1="5857" y1="-925.6" x2="6187" y2="-354"/>
            <line id="Ligne_410-2" class="st5" x1="6517" y1="-925.6" x2="6187" y2="-354"/>
            <line id="Ligne_411-2" class="st5" x1="6758.6" y1="-684.3" x2="6187" y2="-354.3"/>
            <line id="Ligne_412-2" class="st5" x1="5615.4" y1="-684.3" x2="6187" y2="-354.3"/>
            <line id="Ligne_422" class="st5" x1="5527.5" y1="-353.5" x2="6847.5" y2="-353.5"/>
          </g>
          <g id="Groupe_1313" transform="translate(-2.5)">
            <circle id="Ellipse_54" class="st6" cx="6187" cy="306" r="26"/>
            <g id="Ellipse_48" transform="translate(6180.5 297)">
              <circle class="st7" cx="6.5" cy="9" r="7"/>
              <circle class="st8" cx="6.5" cy="9" r="14.5"/>
            </g>
            <g id="Ellipse_49" transform="translate(6167.5 284)" class="st9">
              <circle class="st2" cx="19.5" cy="22" r="20"/>
              <circle class="st10" cx="19.5" cy="22" r="20.5"/>
            </g>
          </g>
          <g id="Groupe_1324" transform="translate(-2.5 -1320)">
            <circle id="Ellipse_54-2" class="st6" cx="6187.5" cy="306.5" r="26"/>
            <g id="Ellipse_48-2" transform="translate(6180.5 297)" class="st11">
              <circle class="st12" cx="7" cy="9.5" r="7"/>
              <circle class="st8" cx="7" cy="9.5" r="14.5"/>
            </g>
            <g id="Ellipse_49-2" transform="translate(6167.5 284)" class="st9">
              <circle class="st2" cx="20" cy="22.5" r="20"/>
              <circle class="st10" cx="20" cy="22.5" r="20.5"/>
            </g>
          </g>
          <g id="Groupe_1314" transform="translate(-332.5 -89.337)">
            <circle id="Ellipse_54-3" class="st6" cx="6187" cy="307.1" r="26"/>
            <g id="Ellipse_48-3" transform="translate(6180.5 297)" class="st11">
              <circle class="st12" cx="6.5" cy="10.1" r="7"/>
              <circle class="st8" cx="6.5" cy="10.1" r="14.5"/>
            </g>
            <g id="Ellipse_49-3" transform="translate(6167.5 284)" class="st9">
              <circle class="st2" cx="19.5" cy="23.1" r="20"/>
              <circle class="st10" cx="19.5" cy="23.1" r="20.5"/>
            </g>
          </g>
          <g id="Groupe_1323" transform="translate(-332.5 -1228.337)">
            <circle id="Ellipse_54-4" class="st6" cx="6187" cy="303.2" r="26"/>
            <g id="Ellipse_48-4" transform="translate(6180.5 297)" class="st11">
              <circle class="st12" cx="6.5" cy="6.2" r="7"/>
              <circle class="st8" cx="6.5" cy="6.2" r="14.5"/>
            </g>
            <g id="Ellipse_49-4" transform="translate(6167.5 284)" class="st9">
              <circle class="st2" cx="19.5" cy="19.2" r="20"/>
              <circle class="st10" cx="19.5" cy="19.2" r="20.5"/>
            </g>
          </g>
          <g id="Groupe_1315" transform="translate(327.5 -89.337)">
            <circle id="Ellipse_54-5" class="st6" cx="6187" cy="306.5" r="26"/>
            <g id="Ellipse_48-5" transform="translate(6180.5 297)" class="st11">
              <circle class="st12" cx="6.5" cy="9.5" r="7"/>
              <circle class="st8" cx="6.5" cy="9.5" r="14.5"/>
            </g>
            <g id="Ellipse_49-5" transform="translate(6167.5 284)" class="st9">
              <circle class="st2" cx="19.5" cy="22.5" r="20"/>
              <circle class="st10" cx="19.5" cy="22.5" r="20.5"/>
            </g>
          </g>
          <g id="Groupe_1322" transform="translate(327.5 -1228.337)">
            <circle id="Ellipse_54-6" class="st6" cx="6186.6" cy="302.7" r="26"/>
            <g id="Ellipse_48-6" transform="translate(6180.5 297)" class="st11">
              <circle class="st12" cx="6.1" cy="5.7" r="7"/>
              <circle class="st8" cx="6.1" cy="5.7" r="14.5"/>
            </g>
            <g id="Ellipse_49-6" transform="translate(6167.5 284)" class="st9">
              <circle class="st2" cx="19.1" cy="18.7" r="20"/>
              <circle class="st10" cx="19.1" cy="18.7" r="20.5"/>
            </g>
          </g>
          <g id="Groupe_1316" transform="translate(569.077 -331.171)">
            <circle id="Ellipse_54-7" class="st6" cx="6186.4" cy="307.7" r="26"/>
            <g id="Ellipse_48-7" transform="translate(6180.5 297)" class="st11">
              <circle class="st12" cx="5.9" cy="10.7" r="7"/>
              <circle class="st8" cx="5.9" cy="10.7" r="14.5"/>
            </g>
            <g id="Ellipse_49-7" transform="translate(6167.5 284)" class="st9">
              <circle class="st2" cx="18.9" cy="23.7" r="20"/>
              <circle class="st10" cx="18.9" cy="23.7" r="20.5"/>
            </g>
          </g>
          <g id="Groupe_1320" transform="translate(569.077 -988.75)">
            <circle id="Ellipse_54-8" class="st6" cx="6187" cy="304.5" r="26"/>
            <g id="Ellipse_48-8" transform="translate(6180.5 297)" class="st11">
              <circle class="st12" cx="6.5" cy="7.5" r="7"/>
              <circle class="st8" cx="6.5" cy="7.5" r="14.5"/>
            </g>
            <g id="Ellipse_49-8" transform="translate(6167.5 284)" class="st9">
              <circle class="st2" cx="19.5" cy="20.5" r="20"/>
              <circle class="st10" cx="19.5" cy="20.5" r="20.5"/>
            </g>
          </g>
          <g id="Groupe_1318" transform="translate(657.5 -659.5)">
            <circle id="Ellipse_54-9" class="st6" cx="6187" cy="306" r="26"/>
            <g id="Ellipse_48-9" transform="translate(6180.5 297)" class="st11">
              <circle class="st12" cx="6.5" cy="9" r="7"/>
              <circle class="st8" cx="6.5" cy="9" r="14.5"/>
            </g>
            <g id="Ellipse_49-9" transform="translate(6167.5 284)" class="st9">
              <circle class="st2" cx="19.5" cy="22" r="20"/>
              <circle class="st10" cx="19.5" cy="22" r="20.5"/>
            </g>
          </g>
          <g id="Groupe_1317" transform="translate(-574.077 -331.171)">
            <circle id="Ellipse_54-10" class="st6" cx="6187" cy="307.7" r="26"/>
            <g id="Ellipse_48-10" transform="translate(6180.5 297)" class="st11">
              <circle class="st12" cx="6.5" cy="10.7" r="7"/>
              <circle class="st8" cx="6.5" cy="10.7" r="14.5"/>
            </g>
            <g id="Ellipse_49-10" transform="translate(6167.5 284)" class="st9">
              <circle class="st2" cx="19.5" cy="23.7" r="20"/>
              <circle class="st10" cx="19.5" cy="23.7" r="20.5"/>
            </g>
          </g>
          <g id="Groupe_1321" transform="translate(-574.077 -988.75)">
            <circle id="Ellipse_54-11" class="st6" cx="6187.2" cy="304.5" r="26"/>
            <g id="Ellipse_48-11" transform="translate(6180.5 297)" class="st11">
              <circle class="st12" cx="6.7" cy="7.5" r="7"/>
              <circle class="st8" cx="6.7" cy="7.5" r="14.5"/>
            </g>
            <g id="Ellipse_49-11" transform="translate(6167.5 284)" class="st9">
              <circle class="st2" cx="19.7" cy="20.5" r="20"/>
              <circle class="st10" cx="19.7" cy="20.5" r="20.5"/>
            </g>
          </g>
          <g id="Groupe_1319" transform="translate(-662.5 -659.5)">
            <circle id="Ellipse_54-12" class="st6" cx="6187" cy="306" r="26"/>
            <g id="Ellipse_48-12" transform="translate(6180.5 297)" class="st11">
              <circle class="st12" cx="6.5" cy="9" r="7"/>
              <circle class="st8" cx="6.5" cy="9" r="14.5"/>
            </g>
            <g id="Ellipse_49-12" transform="translate(6167.5 284)" class="st9">
              <circle class="st2" cx="19.5" cy="22" r="20"/>
              <circle class="st10" cx="19.5" cy="22" r="20.5"/>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>

</template>

<script setup>

import {defineProps, computed, ref, watchEffect} from "vue";
import monthData from '@/data/cycle-months/cycle-vigne.json';
import {languageStore} from "@/stores/languageStore";

const props = defineProps({
  currentSlideIndex: Number,
  isSmaller: Boolean
});

const oldIndex = ref(props.currentSlideIndex.value);
const currentIndex = ref(props.currentSlideIndex.value);
const cancelTransition = ref(false);

watchEffect(() => {
  if (oldIndex.value === 11 && props.currentSlideIndex === 0)  {
    currentIndex.value = 12
    setTimeout(() => {
      cancelTransition.value = true;
      currentIndex.value = 0
      setTimeout(() => {
        cancelTransition.value = false;
      },50)
    }, 350)
  }
  else if (oldIndex.value === 0 && props.currentSlideIndex === 11) {
    currentIndex.value = -1
    setTimeout(() => {
      cancelTransition.value = true;
      currentIndex.value = 11
      setTimeout(() => {
        cancelTransition.value = false;
      },50)
    }, 350)
  }
  else {
    currentIndex.value = props.currentSlideIndex
  }

  oldIndex.value = props.currentSlideIndex
});

// - 2 because svg start in March
const wheelRotation = computed(() => {
  return `${(currentIndex.value - 2) * 360 / 12}deg`;
});

</script>

<style scoped>

.wheel-svg-container {
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  transition: all 350ms ease-in-out;
  height: 375px;
}

.wheel-svg-container--is-smaller {
  height: 170px;
}

.wheel {
  position: absolute;
  top: -950px;
  rotate: v-bind(wheelRotation);
  transition: all 350ms ease-in-out;
}

.wheel-svg-container--is-smaller .wheel {
  top: -1180px;
}

.wheel--no-transition {
  transition: none;
}

.wheel-circle-container {
  position: absolute;
  z-index: 5;
  bottom: 70px;
  transition: all 350ms ease-in-out;
}

.wheel-svg-container--is-smaller .wheel-circle-container {
  bottom: 95px;
}

.wheel-circle {
  display: block;
  background: #000;
  border-radius: 50%;
  width: 14px;
  height: 14px;
}



.st0 {
  font-family: 'Jost';
}

.st1,
.st4 {
  font-size: 30px;
}

.st2 {
  fill: none;
}

.st3 {
  fill: none;
  stroke: #BCBCBA;
}

.st5 {
  fill: none;
  stroke: #E4E3E1;
}

.st6 {
  fill: #F8F7F4;
}

.st7 {
  fill: #36332D;
  fill-opacity: 0;
}

.st8 {
  fill: none;
  stroke: #F8F7F4;
  stroke-width: 15;
}

.st9 {
  opacity: 0.3;
}

.st10 {
  fill: none;
  stroke: #616161;
}

.st11 {
  opacity: 0;
}

.st12 {
  fill: #36332D;
}

</style>