<template>
  <div class="item-video-container">
    <video ref="videoRef" :src="videoPath" height="325" preload="auto" controls="controls"></video>
    <VinificationPlayBtn :visible="!isPlaying" @click="playVideo"/>
  </div>
  <div class="item-content-container">
    <div class="item-title-container">
      <h2 class="item-title">{{ wineStep.title[languageStore.lang] }}</h2>
    </div>
    <div class="item-description-container">
      <p v-html="wineStep.description[languageStore.lang]"></p>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref, onMounted, onUnmounted } from 'vue';
import { useRoute } from "vue-router";
import VinificationPlayBtn from "@/components/vinification-timeline/VinificationPlayBtn.vue";
import {languageStore} from "@/stores/languageStore";

const route = useRoute();
const { type } = route.params;

const props = defineProps({
  wineStep: Object
});

const videoPath = `/videos/vinification/${type}/${props.wineStep.video}`;
const videoRef = ref(null);
const isPlaying = ref(false);

const playVideo = () => {
  if (videoRef.value) {
    videoRef.value.play().then(() => {
      isPlaying.value = true;
      stopAllOtherVideos();
    }).catch(err => {
      console.error("Error playing video:", err.message);
    });
  }
};

const stopAllOtherVideos = () => {
  document.querySelectorAll('video').forEach(video => {
    if (video !== videoRef.value) {
      video.pause();
    }
  });
}

onMounted(() => {
  videoRef.value.addEventListener('play', playVideo);
});

onUnmounted(() => {
  if (videoRef.value) {
    videoRef.value.removeEventListener('play', playVideo);
  }
});

</script>

<style scoped>

/*Single item*/

.item-video-container {
  position: relative;
  max-width: 100%;
  height: 325px;
}

.item-video-container video {
  max-width: 100%;
}

.item-title {
  font-size: 27px;
  font-weight: 500;
  color: #2A2721;
  text-align: center;
  padding: 20px 0;
  margin: 0;
}

.item-description-container {
  color: var(--text-color);
  text-align: center;
  width: 385px;
}

video::-webkit-media-controls-panel {
  background-image: none !important;
  filter: brightness(0.4);
}

video::-webkit-media-controls-panel {
  opacity: 0;
}

</style>