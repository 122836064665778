<template>
  <swiper :slides-per-view="1"
          :space-between="50"
          :initialSlide="currentMonthIndex"
          @slideChange="onSlideChange"
          class="cycle-month-list-container"
          ref="swiperRef"
          :navigation="{
            prevEl: '.swiper-button-prev',
            nextEl: '.swiper-button-next'
          }"
          @swiper="onSwiperInitialized"
          :loop="true"
  >
    <div class="swiper-btns-container" :class="{'swiper-btns-container--higher': isHidden}">
      <div class="swiper-button-prev" @click="prevSlide">
        <svg xmlns="http://www.w3.org/2000/svg" width="8.546" height="14.546" viewBox="0 0 8.546 14.546">
          <g transform="translate(1.273 1.273)">
            <line x2="6" y2="6" transform="translate(0 6)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="1.8"/>
            <line x2="6" y2="6" transform="translate(0 6) rotate(-90)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="1.8"/>
          </g>
        </svg>
      </div>
      <div class="swiper-button-next" @click="nextSlide">
        <svg xmlns="http://www.w3.org/2000/svg" width="8.546" height="14.546" viewBox="0 0 8.546 14.546">
          <g transform="translate(59.273 56.773) rotate(180)">
            <line y1="6" x2="6" transform="translate(52 43.5)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="1.8"/>
            <line y1="6" x2="6" transform="translate(58 49.5) rotate(90)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="1.8"/>
          </g>
        </svg>
      </div>
    </div>
    <swiper-slide
        v-for="(monthsCardList, index) in props.monthList"
        :key="index"
        :class="['month-' + index, {'cycle-month-list-container--is-hidden': isHidden}]"
    >
      <CycleMonth
          :monthsCardList="monthsCardList"
          @button-click="handleButtonClick"
      />
    </swiper-slide>
  </swiper>
  <img :src="leftOverlay" alt="" class="left-overlay img-overlay">
  <img :src="rightOverlay" alt="" class="right-overlay img-overlay">
</template>

<script setup>

import {defineEmits, defineProps} from 'vue';
import CycleMonth from "@/components/cycle/CycleMonth.vue";
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/css';
import leftOverlay from '@/assets/img/elements/left-overlay.png';
import rightOverlay from '@/assets/img/elements/right-overlay.png';

const props = defineProps({
  monthList: Array,
  isHidden: Boolean,
  cycleMonthSwiperRef: Object,
  setCycleMonthSwiperRef: Function
});

const emit = defineEmits(['slide-changed', 'button-click']);
const onSlideChange = (swiper) => {
  const currentIndex = swiper.realIndex;
  emit('slide-changed', currentIndex);
};

const onSwiperInitialized = (swiper) => {
  props.setCycleMonthSwiperRef(swiper);
};

const prevSlide = () => {
  if (props.cycleMonthSwiperRef) {
    props.cycleMonthSwiperRef.slidePrev();
  }
};

const nextSlide = () => {
  if (props.cycleMonthSwiperRef) {
    props.cycleMonthSwiperRef.slideNext();
  }
};

const currentDate = new Date();
const currentMonthIndex = currentDate.getMonth();

const handleButtonClick = (data) => {
  emit('button-click', data);
};
</script>

<style scoped>

.swiper {
  max-width: 750px;
  margin: 0 auto;
  overflow: visible;
}

.cycle-month-list-container {
  position: relative;
  padding-top: 3em;
}

.cycle-month-list-container--is-hidden {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  height: 0;
}

.img-overlay {
  position: absolute;
  z-index: 1;
  bottom: 0;
  pointer-events: none;
}

.right-overlay {
  right: 0;
}

.swiper-btns-container {
  position: absolute;
  bottom: calc(100% + 15px);
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  gap: 180px;
}

.swiper-btns-container--higher {
  bottom: calc(100% + 35px);
}

.swiper-btns-container svg {
  cursor: pointer;
}

</style>