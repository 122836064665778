<template>

  <VinificationTitle :title="props.title"/>
  <div class="vinification-steps-timeline-container">
    <VinificationStepList :wineStepList="props.wineStepList" :currentIndex="currentIndex" :setCurrentIndex="setCurrentIndex"/>
    <VinificationStepCardList :wineStepList="props.wineStepList" :currentIndex="currentIndex" :setCurrentIndex="setCurrentIndex"/>
  </div>

</template>

<script setup>

import {defineProps, ref} from 'vue';
import VinificationTitle from "@/components/vinification-timeline/VinificationTitle.vue";
import VinificationStepList from "@/components/vinification-timeline/VinificationStepList.vue";
import VinificationStepCardList from "@/components/vinification-timeline/VinificationStepCardList.vue";

const props = defineProps({
  title: String,
  wineStepList: Array
});

const currentIndex = ref(0);

const setCurrentIndex = (index) => {
  currentIndex.value = index;
}

</script>

<style scoped>

.vinification-steps-timeline-container {
  display: flex;
  column-gap: 200px;
}

</style>