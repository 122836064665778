<template>
  <ul class="header-menu-links-list">
    <li
        v-for="(page, index) in menuData"
        :key="index"
        @click="navigate(page.url)"
        :class="[computedClasses(page), { 'header-menu-link--is-active': isActive(page.url) }]"
    >
      <router-link :to="page.url" v-html="page.title[languageStore.lang] ? page.title[languageStore.lang] : page.title" v-if="page.url !== 'back'"></router-link>
      <span v-html="page.title[languageStore.lang] ? page.title[languageStore.lang] : page.title" v-else></span>
    </li>
  </ul>
</template>

<script setup>
import { useRouter, useRoute } from 'vue-router';
import { languageStore } from "@/stores/languageStore";
import menuData from "@/data/menu/menu.json";

const router = useRouter();
const route = useRoute();

const navigate = (url) => {
  if (url === 'back') {
    router.go(-1);
  } else {
    router.push(url);
  }
};

const computedClasses = (page) => {
  return {
    'header-menu-link': !page.customClasses,
    [page.customClasses]: !!page.customClasses
  };
}

const isActive = (url) => {
  return route.path === url;
}

</script>

<style scoped>

.header-menu-links-list {
  display: flex;
  align-items: center;
  width: 750px;
  height: 100%;
  margin-left: 30px;
}

.header-menu-links-list li {
  cursor: pointer;
}

.header-menu-icon-link {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-color);
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.header-menu-back-link {
  margin-right: 20px;
}

.header-menu-link a {
  color: #000;
  margin-left: 40px;
}

li:not(.header-menu-icon-link).header-menu-link--is-active a {
  border-bottom: 2px solid #000;
}

</style>