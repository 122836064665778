<template>
  <div class="wines-map-container">
    <div class="svg-container panzoom--area" style="height: 100vh; width: 100vw">
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
           viewBox="0 0 8719 12622" enable-background="new 0 0 8719 12622" xml:space="preserve">
      <image overflow="visible" :href="imagePath"></image>
        <g id="pointeurs">
          <g id="wine-97"
             transform="translate(0 0)"
             class="pointer element-w-tooltip"
             @mousedown="(event) => handlePointerClick(event, 'wine-97')"
             @touchstart="(event) => handlePointerClick(event, 'wine-97')"
             :class="{ 'element-w-tooltip--is-active': wineStore.selectedWine === 'wine-97' }"
             data-wineId="vin-97"
          >
            <g id="label_8_" class="st2">
              <g transform="matrix(1, 0, 0, 1, 0, 0)" class="st3">
                <path id="Rectangle_49-2_8_" class="st4" d="M1936.1,9817.4h262.3c2.8,0,5.1,2.1,5.1,4.8v104.9c0,2.6-2.2,4.8-5.1,4.8h-262.3
                  c-2.8,0-5.1-2.1-5.1-4.8v-104.9C1931.1,9819.6,1933.3,9817.4,1936.1,9817.4z"/>
              </g>
              <g id="btn_8_" transform="translate(-979 -170)" class="st3">
                <g id="Rectangle_46_8_" transform="translate(1126 170)">
                  <path class="st0" d="M2056.3,9787.1L2056.3,9787.1c15.8,0,28.6,12.8,28.6,28.6l0,0c0,15.8-12.8,28.6-28.6,28.6l0,0
                    c-15.8,0-28.6-12.8-28.6-28.6l0,0C2027.7,9800,2040.5,9787.1,2056.3,9787.1z"/>
                  <path class="st8" d="M2056.3,9787.9L2056.3,9787.9c15.4,0,27.8,12.5,27.8,27.8l0,0c0,15.4-12.5,27.8-27.8,27.8l0,0
                    c-15.4,0-27.8-12.5-27.8-27.8l0,0C2028.5,9800.4,2041,9787.9,2056.3,9787.9z"/>
                </g>

                <g id="add-circle--button-remove-cross-add-buttons-plus-circle-_-mathematics-math_8_" transform="translate(1143.706 180.401) rotate(45)">
                  <path id="Vector_2_8_" class="st9" d="M8374.6,5483.7v16.9"/>
                  <path id="Vector_3_8_" class="st9" d="M8366.1,5492.1h16.9"/>
                </g>
              </g>
              <text transform="matrix(1 0 0 1 1998.7319 9868.1309)" class="st3 st5 st6">Bourgogne</text>
              <text transform="matrix(1 0 0 1 1949.8171 9900.5313)" class="st3 st5 st7">Haute Côtes de Beaune</text>
            </g>
            <g id="pointeur_7_"
               class="pointer scroll-to-zone"
               x="-126"
               y="-4122"
            >
              <path id="Tracé_701_15_" class="st10" d="M2091,10003.8c4.6-6.2,8.9-12.6,12.6-19.4l0.2-0.2c3.8-5.9,5.9-12.9,5.8-19.9
                c0.1-20.2-16.2-36.6-36.3-36.6c-20.2-0.1-36.6,16.2-36.6,36.3c0,0.1,0,0.2,0,0.3c0.1,3.5,0.6,7,1.4,10.4c1.1,3.3,2.6,6.4,4.4,9.3
                l0.2,0.4c3.8,6.8,8.1,13.3,12.8,19.4c6.8,9.7,13.8,19.4,17.6,30.7C2077,10023.2,2084,10013.5,2091,10003.8L2091,10003.8z"/>
              <circle id="Ellipse_11_15_" class="st11" cx="2073.9" cy="9962.6" r="16.6"/>
            </g>
          </g>
          <g id="wine-98"
             transform="translate(0 0)"
             class="pointer element-w-tooltip"
             @mousedown="(event) => handlePointerClick(event, 'wine-98')"
             @touchstart="(event) => handlePointerClick(event, 'wine-98')"
             :class="{ 'element-w-tooltip--is-active': wineStore.selectedWine === 'wine-98' }"
          >
            <g id="label_2_" class="st2">
              <g transform="matrix(1, 0, 0, 1, 0, 0)" class="st3">
                <path id="Rectangle_49-2_2_" class="st4" d="M2587.2,10964.9H2904c3.3,0,6.2,2.1,6.2,4.8v104.9c0,2.6-2.7,4.8-6.2,4.8h-316.8
                  c-3.3,0-6.2-2.1-6.2-4.8v-104.9C2581.1,10967.1,2583.8,10964.9,2587.2,10964.9z"/>
              </g>
              <g id="btn_2_" transform="translate(-979 -170)" class="st3">
                <g id="Rectangle_46_2_" transform="translate(1126 170)">
                  <path class="st0" d="M2764.2,10935.6L2764.2,10935.6c15.8,0,28.6,12.8,28.6,28.6l0,0c0,15.8-12.8,28.6-28.6,28.6l0,0
                    c-15.8,0-28.6-12.8-28.6-28.6l0,0C2735.6,10948.5,2748.4,10935.6,2764.2,10935.6z"/>
                  <path class="st8" d="M2764.2,10936.4L2764.2,10936.4c15.4,0,27.8,12.5,27.8,27.8l0,0c0,15.4-12.5,27.8-27.8,27.8l0,0
                    c-15.4,0-27.8-12.5-27.8-27.8l0,0C2736.4,10948.9,2748.9,10936.4,2764.2,10936.4z"/>
                </g>

                <g id="add-circle--button-remove-cross-add-buttons-plus-circle-_-mathematics-math_2_" transform="translate(1143.706 180.401) rotate(45)">
                  <path id="Vector_2_2_" class="st9" d="M9687.3,5795.3v16.9"/>
                  <path id="Vector_3_2_" class="st9" d="M9678.8,5803.7h16.9"/>
                </g>
              </g>
              <text transform="matrix(1 0 0 1 2599.7263 11014.6709)" class="st3 st5 st6">Chassagne-Montrachet</text>
              <text transform="matrix(1 0 0 1 2674.9207 11044.0703)" class="st3 st5 st7">Les Battaudes</text>
            </g>
            <g id="pointeur_2_"
               class="pointer scroll-to-zone"
               x="-640"
               y="-4660"
            >
                <path id="Tracé_701_1_" class="st10" d="M2763.5,11143.1c4.6-6.2,8.9-12.6,12.6-19.4l0.2-0.2c3.8-5.9,5.9-12.9,5.8-19.9
                  c0.1-20.2-16.2-36.6-36.3-36.6c-20.2-0.1-36.6,16.2-36.6,36.3c0,0.1,0,0.2,0,0.3c0.1,3.5,0.6,7,1.4,10.4c1.1,3.3,2.6,6.4,4.4,9.3
                  l0.2,0.4c3.8,6.8,8.1,13.3,12.8,19.4c6.8,9.7,13.8,19.4,17.6,30.7C2749.6,11162.5,2756.5,11152.8,2763.5,11143.1L2763.5,11143.1z
                  "/>
              <circle id="Ellipse_11_1_" class="st11" cx="2746.4" cy="11101.9" r="16.6"/>
            </g>
              </g>
          <g id="wine-99"
             transform="translate(0 0)"
             class="pointer element-w-tooltip"
             @mousedown="(event) => handlePointerClick(event, 'wine-99')"
             @touchstart="(event) => handlePointerClick(event, 'wine-99')"
             :class="{ 'element-w-tooltip--is-active': wineStore.selectedWine === 'wine-99' }"
          >
            <g id="label_1_" class="st2">
              <g transform="matrix(1, 0, 0, 1, 0, 0)" class="st3">
                <path id="Rectangle_49-2_1_" class="st4" d="M3088.3,10186.5H3378c3.1,0,5.6,2.1,5.6,4.8v104.9c0,2.6-2.5,4.8-5.6,4.8h-289.7
                  c-3.1,0-5.6-2.1-5.6-4.8v-104.9C3082.8,10188.7,3085.2,10186.5,3088.3,10186.5z"/>
              </g>
              <text transform="matrix(1 0 0 1 3112.1868 10236.2285)" class="st3 st5 st6">Puligny-Montrachet</text>
              <text transform="matrix(1 0 0 1 3124.5671 10267.6289)" class="st3 st5 st7">La Corvée des Vignes</text>
              <g id="btn_1_" transform="translate(-979 -170)" class="st3">
                <g id="Rectangle_46_1_" transform="translate(1126 170)">
                  <path class="st0" d="M3238.7,10157.2L3238.7,10157.2c15.8,0,28.6,12.8,28.6,28.6l0,0c0,15.8-12.8,28.6-28.6,28.6l0,0
                    c-15.8,0-28.6-12.8-28.6-28.6l0,0C3210.1,10170.1,3222.9,10157.2,3238.7,10157.2z"/>
                  <path class="st8" d="M3238.7,10158L3238.7,10158c15.4,0,27.8,12.5,27.8,27.8l0,0c0,15.4-12.5,27.8-27.8,27.8l0,0
                    c-15.4,0-27.8-12.5-27.8-27.8l0,0C3210.9,10170.5,3223.4,10158,3238.7,10158z"/>
                </g>

                <g id="add-circle--button-remove-cross-add-buttons-plus-circle-_-mathematics-math_1_" transform="translate(1143.706 180.401) rotate(45)">
                  <path id="Vector_2_1_" class="st9" d="M9472.4,4909.3v16.9"/>
                  <path id="Vector_3_1_" class="st9" d="M9463.9,4917.7h16.9"/>
                </g>
              </g>
            </g>
            <g id="pointeur_1_"
               class="pointer scroll-to-zone"
               x="-630"
               y="-4295"
            >
              <path id="Tracé_701" class="st10" d="M3250.9,10369.1c4.6-6.2,8.9-12.6,12.6-19.4l0.2-0.2c3.8-5.9,5.9-12.9,5.8-19.9
                c0.1-20.2-16.2-36.6-36.3-36.6c-20.2-0.1-36.6,16.2-36.6,36.3c0,0.1,0,0.2,0,0.3c0.1,3.5,0.6,7,1.4,10.4c1.1,3.3,2.6,6.4,4.4,9.3
                l0.2,0.4c3.8,6.8,8.1,13.3,12.8,19.4c6.8,9.7,13.8,19.4,17.6,30.7C3237,10388.4,3243.9,10378.8,3250.9,10369.1L3250.9,10369.1z"
              />
              <circle id="Ellipse_11" class="st11" cx="3233.8" cy="10327.8" r="16.6"/>
            </g>
          </g>
          <g id="wine-100"
             transform="translate(0 0)"
             class="pointer element-w-tooltip"
             @mousedown="(event) => handlePointerClick(event, 'wine-100')"
             @touchstart="(event) => handlePointerClick(event, 'wine-100')"
             :class="{ 'element-w-tooltip--is-active': wineStore.selectedWine === 'wine-100' }"
             @click="selectedWineData"
          >
            <g id="label" class="st2">
              <g transform="matrix(1, 0, 0, 1, 0, 0)" class="st3">
                <path id="Rectangle_49-2" class="st4" d="M3166.1,9697.9h246.3c2.6,0,4.8,2.1,4.8,4.8v104.9c0,2.6-2.1,4.8-4.8,4.8h-246.3
                  c-2.6,0-4.8-2.1-4.8-4.8v-104.9C3161.4,9700.1,3163.5,9697.9,3166.1,9697.9z"/>
              </g>
              <g id="btn" transform="translate(-979 -170)" class="st3">
                <g id="Rectangle_46" transform="translate(1126 170)">
                  <path class="st0" d="M3271.3,9667.6L3271.3,9667.6c15.8,0,28.6,12.8,28.6,28.6l0,0c0,15.8-12.8,28.6-28.6,28.6l0,0
                    c-15.8,0-28.6-12.8-28.6-28.6l0,0C3242.7,9680.5,3255.5,9667.6,3271.3,9667.6z"/>
                  <path class="st8" d="M3271.3,9668.4L3271.3,9668.4c15.4,0,27.8,12.5,27.8,27.8l0,0c0,15.4-12.5,27.8-27.8,27.8l0,0
                    c-15.4,0-27.8-12.5-27.8-27.8l0,0C3243.5,9680.9,3256,9668.4,3271.3,9668.4z"/>
                </g>

                <g id="add-circle--button-remove-cross-add-buttons-plus-circle-_-mathematics-math" transform="translate(1143.706 180.401) rotate(45)">
                  <path id="Vector_2" class="st9" d="M9149.2,4540.1v16.9"/>
                  <path id="Vector_3" class="st9" d="M9140.7,4548.5h16.9"/>
                </g>
              </g>
              <text transform="matrix(1 0 0 1 3227.8328 9747.6309)" class="st3 st5 st6">Meursault</text>
              <text transform="matrix(1 0 0 1 3184.1328 9776.0313)" class="st3 st5 st7">Les Grands Charrons</text>
            </g>
            <g id="pointeur"
               class="pointer scroll-to-zone"
               x="-680"
               y="-4090"
            >
              <path id="Tracé_701_5_" class="st10" d="M3306.3,9879.1c4.6-6.2,8.9-12.6,12.6-19.4l0.2-0.2c3.8-5.9,5.9-12.9,5.8-19.9
                c0.1-20.2-16.2-36.6-36.3-36.6c-20.2-0.1-36.6,16.2-36.6,36.3c0,0.1,0,0.2,0,0.3c0.1,3.5,0.6,7,1.4,10.4c1.1,3.3,2.6,6.4,4.4,9.3
                l0.2,0.4c3.8,6.8,8.1,13.3,12.8,19.4c6.8,9.7,13.8,19.4,17.6,30.7C3292.3,9898.5,3299.3,9888.8,3306.3,9879.1L3306.3,9879.1z"/>
              <circle id="Ellipse_11_2_" class="st11" cx="3289.2" cy="9837.9" r="16.6"/>
            </g>
        </g>
          <g id="wine-101"
             transform="translate(0 0)"
             class="pointer element-w-tooltip"
             @mousedown="(event) => handlePointerClick(event, 'wine-101')"
             @touchstart="(event) => handlePointerClick(event, 'wine-101')"
             :class="{ 'element-w-tooltip--is-active': wineStore.selectedWine === 'wine-101' }"
          >
            <g id="label_11_" class="st2">
              <g transform="matrix(1, 0, 0, 1, 0, 0)" class="st3">
                <path id="Rectangle_49-2_11_" class="st4" d="M2321.3,9542.7h262.3c2.8,0,5.1,2.1,5.1,4.8v104.9c0,2.6-2.2,4.8-5.1,4.8h-262.3
                  c-2.8,0-5.1-2.1-5.1-4.8v-104.9C2316.3,9544.9,2318.6,9542.7,2321.3,9542.7z"/>
              </g>
              <g id="btn_11_" transform="translate(-979 -170)" class="st3">
                <g id="Rectangle_46_11_" transform="translate(1126 170)">
                  <path class="st0" d="M2441.6,9512.4L2441.6,9512.4c15.8,0,28.6,12.8,28.6,28.6l0,0c0,15.8-12.8,28.6-28.6,28.6l0,0
                    c-15.8,0-28.6-12.8-28.6-28.6l0,0C2413,9525.3,2425.8,9512.4,2441.6,9512.4z"/>
                  <path class="st8" d="M2441.6,9513.2L2441.6,9513.2c15.4,0,27.8,12.5,27.8,27.8l0,0c0,15.4-12.5,27.8-27.8,27.8l0,0
                    c-15.4,0-27.8-12.5-27.8-27.8l0,0C2413.8,9525.7,2426.3,9513.2,2441.6,9513.2z"/>
                </g>

                <g id="add-circle--button-remove-cross-add-buttons-plus-circle-_-mathematics-math_11_" transform="translate(1143.706 180.401) rotate(45)">
                  <path id="Vector_2_11_" class="st9" d="M8452.7,5017.1v16.9"/>
                  <path id="Vector_3_11_" class="st9" d="M8444.2,5025.5h16.9"/>
                </g>
              </g>
              <text transform="matrix(1 0 0 1 2349.7996 9593.4297)" class="st3 st5 st6">Auxey-Duresses</text>
              <text transform="matrix(1 0 0 1 2397.4702 9625.8301)" class="st3 st5 st7">Le Porolley</text>
            </g>
            <g id="pointeur_6_"
               class="pointer scroll-to-zone"
               x="-250"
               y="-3940"
            >
              <path id="Tracé_701_7_" class="st10" d="M2474.8,9728.3c4.6-6.2,8.9-12.6,12.6-19.4l0.2-0.2c3.8-5.9,5.9-12.9,5.8-19.9
                c0.1-20.2-16.2-36.6-36.3-36.6c-20.2-0.1-36.6,16.2-36.6,36.3c0,0.1,0,0.2,0,0.3c0.1,3.5,0.6,7,1.4,10.4c1.1,3.3,2.6,6.4,4.4,9.3
                l0.2,0.4c3.8,6.8,8.1,13.3,12.8,19.4c6.8,9.7,13.8,19.4,17.6,30.7C2460.8,9747.7,2467.8,9738,2474.8,9728.3L2474.8,9728.3z"/>
              <circle id="Ellipse_11_7_" class="st11" cx="2457.7" cy="9687.1" r="16.6"/>
            </g>
          </g>
          <g id="wine-102"
             transform="translate(0 0)"
             class="pointer element-w-tooltip"
             @mousedown="(event) => handlePointerClick(event, 'wine-102')"
             @touchstart="(event) => handlePointerClick(event, 'wine-102')"
             :class="{ 'element-w-tooltip--is-active': wineStore.selectedWine === 'wine-102' }"
          >
           <g id="label_9_" class="st2">
              <g transform="matrix(1, 0, 0, 1, 0, 0)" class="st3">
                <path id="Rectangle_49-2_9_" class="st4" d="M2401.2,9211.9h262.3c2.8,0,5.1,2.1,5.1,4.8v104.9c0,2.6-2.2,4.8-5.1,4.8h-262.3
                  c-2.8,0-5.1-2.1-5.1-4.8v-104.9C2396.2,9214.1,2398.4,9211.9,2401.2,9211.9z"/>
              </g>
             <g id="btn_9_" transform="translate(-979 -170)" class="st3">
                <g id="Rectangle_46_9_" transform="translate(1126 170)">
                  <path class="st0" d="M2521.4,9181.6L2521.4,9181.6c15.8,0,28.6,12.8,28.6,28.6l0,0c0,15.8-12.8,28.6-28.6,28.6l0,0
                    c-15.8,0-28.6-12.8-28.6-28.6l0,0C2492.8,9194.5,2505.6,9181.6,2521.4,9181.6z"/>
                  <path class="st8" d="M2521.4,9182.4L2521.4,9182.4c15.4,0,27.8,12.5,27.8,27.8l0,0c0,15.4-12.5,27.8-27.8,27.8l0,0
                    c-15.4,0-27.8-12.5-27.8-27.8l0,0C2493.6,9194.9,2506.1,9182.4,2521.4,9182.4z"/>
                </g>

               <g id="add-circle--button-remove-cross-add-buttons-plus-circle-_-mathematics-math_9_" transform="translate(1143.706 180.401) rotate(45)">
                  <path id="Vector_2_9_" class="st9" d="M8275.3,4726.7v16.9"/>
                 <path id="Vector_3_9_" class="st9" d="M8266.8,4735.1h16.9"/>
                </g>
              </g>
             <text transform="matrix(1 0 0 1 2447.5598 9262.6035)" class="st3 st5 st6">Saint-Romain</text>
             <text transform="matrix(1 0 0 1 2462.6563 9295.0039)" class="st3 st5 st7">Sous Château</text>
            </g>
            <g id="pointeur_5_"
               class="pointer scroll-to-zone"
               x="-330"
               y="-3890"
            >
              <path id="Tracé_701_6_" class="st10" d="M2545,9395.2c4.6-6.2,8.9-12.6,12.6-19.4l0.2-0.2c3.8-5.9,5.9-12.9,5.8-19.9
                c0.1-20.2-16.2-36.6-36.3-36.6c-20.2-0.1-36.6,16.2-36.6,36.3c0,0.1,0,0.2,0,0.3c0.1,3.5,0.6,7,1.4,10.4c1.1,3.3,2.6,6.4,4.4,9.3
                l0.2,0.4c3.8,6.8,8.1,13.3,12.8,19.4c6.8,9.7,13.8,19.4,17.6,30.7C2531,9414.5,2538,9404.9,2545,9395.2L2545,9395.2z"/>
              <circle id="Ellipse_11_6_" class="st11" cx="2527.9" cy="9354" r="16.6"/>
            </g>
          </g>
          <g id="wine-103"
             transform="translate(0 0)"
             class="pointer element-w-tooltip"
             @mousedown="(event) => handlePointerClick(event, 'wine-103')"
             @touchstart="(event) => handlePointerClick(event, 'wine-103')"
             :class="{ 'element-w-tooltip--is-active': wineStore.selectedWine === 'wine-103' }"
          >
          >
            <g id="label_7_" class="st2">
              <g transform="matrix(1, 0, 0, 1, 0, 0)" class="st3">
                <path id="Rectangle_49-2_7_" class="st4" d="M1396.7,11688.4H1659c2.8,0,5.1,2.1,5.1,4.8v104.9c0,2.6-2.2,4.8-5.1,4.8h-262.3
                  c-2.8,0-5.1-2.1-5.1-4.8v-104.9C1391.7,11690.6,1394,11688.4,1396.7,11688.4z"/>
              </g>
              <g id="btn_7_" transform="translate(-979 -170)" class="st3">
                <g id="Rectangle_46_7_" transform="translate(1126 170)">
                  <path class="st0" d="M1517,11658.1L1517,11658.1c15.8,0,28.6,12.8,28.6,28.6l0,0c0,15.8-12.8,28.6-28.6,28.6l0,0
                    c-15.8,0-28.6-12.8-28.6-28.6l0,0C1488.4,11671,1501.2,11658.1,1517,11658.1z"/>
                  <path class="st8" d="M1517,11658.9L1517,11658.9c15.4,0,27.8,12.5,27.8,27.8l0,0c0,15.4-12.5,27.8-27.8,27.8l0,0
                    c-15.4,0-27.8-12.5-27.8-27.8l0,0C1489.2,11671.4,1501.7,11658.9,1517,11658.9z"/>
                </g>

                <g id="add-circle--button-remove-cross-add-buttons-plus-circle-_-mathematics-math_7_" transform="translate(1143.706 180.401) rotate(45)">
                  <path id="Vector_2_7_" class="st9" d="M9316.2,7188.1v16.9"/>
                  <path id="Vector_3_7_" class="st9" d="M9307.7,7196.5h16.9"/>
                </g>
              </g>
              <text transform="matrix(1 0 0 1 1459.3997 11739.1299)" class="st3 st5 st6">Bourgogne</text>
              <text transform="matrix(1 0 0 1 1410.4849 11771.5303)" class="st3 st5 st7">Haute Côtes de Beaune</text>
            </g>
            <g id="pointeur_12_"
               class="pointer scroll-to-zone"
               x="0"
               y="-4660"
            >
              <path id="Tracé_701_14_" class="st12" d="M1542.6,11872c4.6-6.2,8.9-12.6,12.6-19.4l0.2-0.2c3.8-5.9,5.9-12.9,5.8-19.9
                c0.1-20.2-16.2-36.6-36.3-36.6c-20.2-0.1-36.6,16.2-36.6,36.3c0,0.1,0,0.2,0,0.3c0.1,3.5,0.6,7,1.4,10.4c1.1,3.3,2.6,6.4,4.4,9.3
                l0.2,0.4c3.8,6.8,8.1,13.3,12.8,19.4c6.8,9.7,13.8,19.4,17.6,30.7C1528.7,11891.3,1535.6,11881.7,1542.6,11872L1542.6,11872z"/>
              <circle id="Ellipse_11_14_" class="st11" cx="1525.5" cy="11830.8" r="16.6"/>
            </g>
          </g>
          <g id="wine-104"
             transform="translate(0 0)"
             class="pointer element-w-tooltip"
             @mousedown="(event) => handlePointerClick(event, 'wine-104')"
             @touchstart="(event) => handlePointerClick(event, 'wine-104')"
             :class="{ 'element-w-tooltip--is-active': wineStore.selectedWine === 'wine-104' }"
          >
            <g id="label_13_" class="st2">
              <g transform="matrix(1, 0, 0, 1, 0, 0)" class="st3">
                <path id="Rectangle_49-2_13_" class="st4" d="M6202.8,4899h290c3.1,0,5.7,2.1,5.7,4.8v104.9c0,2.6-2.5,4.8-5.7,4.8h-290
                  c-3.1,0-5.7-2.1-5.7-4.8v-104.9C6197.3,4901.2,6199.8,4899,6202.8,4899z"/>
              </g>
              <g id="btn_13_" transform="translate(-979 -170)" class="st3">
                <g id="Rectangle_46_13_" transform="translate(1126 170)">
                  <path class="st0" d="M6351.4,4868.7L6351.4,4868.7c15.8,0,28.6,12.8,28.6,28.6l0,0c0,15.8-12.8,28.6-28.6,28.6l0,0
                    c-15.8,0-28.6-12.8-28.6-28.6l0,0C6322.8,4881.6,6335.6,4868.7,6351.4,4868.7z"/>
                  <path class="st8" d="M6351.4,4869.5L6351.4,4869.5c15.4,0,27.8,12.5,27.8,27.8l0,0c0,15.4-12.5,27.8-27.8,27.8l0,0
                    c-15.4,0-27.8-12.5-27.8-27.8l0,0C6323.6,4882,6336.1,4869.5,6351.4,4869.5z"/>
                </g>

                <g id="add-circle--button-remove-cross-add-buttons-plus-circle-_-mathematics-math_13_" transform="translate(1143.706 180.401) rotate(45)">
                  <path id="Vector_2_13_" class="st9" d="M7933.8-1031.3v16.9"/>
                  <path id="Vector_3_13_" class="st9" d="M7925.3-1022.9h16.9"/>
                </g>
              </g>
              <text transform="matrix(1 0 0 1 6224.0859 4949.6831)" class="st3 st5 st6">Chambolle-Musigny</text>
              <text transform="matrix(1 0 0 1 6283.6074 4982.0835)" class="st3 st5 st7">Les Nazoires</text>
            </g>
            <g id="pointeur_13_"
               class="pointer scroll-to-zone"
               x="-2095"
               y="-1870"
            >
              <path id="Tracé_701_11_" class="st12" d="M6364.4,5085.7c4.6-6.2,8.9-12.6,12.6-19.4l0.2-0.2c3.8-5.9,5.9-12.9,5.8-19.9
                c0.1-20.2-16.2-36.6-36.3-36.6c-20.2-0.1-36.6,16.2-36.6,36.3c0,0.1,0,0.2,0,0.3c0.1,3.5,0.6,7,1.4,10.4c1.1,3.3,2.6,6.4,4.4,9.3
                l0.2,0.4c3.8,6.8,8.1,13.3,12.8,19.4c6.8,9.7,13.8,19.4,17.6,30.7C6350.5,5105,6357.4,5095.4,6364.4,5085.7L6364.4,5085.7z"/>
              <circle id="Ellipse_11_11_" class="st11" cx="6347.4" cy="5044.5" r="16.6"/>
            </g>
          </g>
          <g id="wine-105"
             transform="translate(0 0)"
             class="pointer element-w-tooltip"
             @mousedown="(event) => handlePointerClick(event, 'wine-105')"
             @touchstart="(event) => handlePointerClick(event, 'wine-105')"
             :class="{ 'element-w-tooltip--is-active': wineStore.selectedWine === 'wine-105' }"
          >
          >
            <g id="label_14_" class="st2">
              <g transform="matrix(1, 0, 0, 1, 0, 0)" class="st3">
                <path id="Rectangle_49-2_14_" class="st4" d="M6544.6,3715.5h290c3.1,0,5.7,2.1,5.7,4.8v104.9c0,2.6-2.5,4.8-5.7,4.8h-290
                  c-3.1,0-5.7-2.1-5.7-4.8v-104.9C6539,3717.7,6541.5,3715.5,6544.6,3715.5z"/>
              </g>
              <g id="btn_14_" transform="translate(-979 -170)" class="st3">
                <g id="Rectangle_46_14_" transform="translate(1126 170)">
                  <path class="st0" d="M6693.2,3685.2L6693.2,3685.2c15.8,0,28.6,12.8,28.6,28.6l0,0c0,15.8-12.8,28.6-28.6,28.6l0,0
                    c-15.8,0-28.6-12.8-28.6-28.6l0,0C6664.6,3698.1,6677.4,3685.2,6693.2,3685.2z"/>
                  <path class="st8" d="M6693.2,3686L6693.2,3686c15.4,0,27.8,12.5,27.8,27.8l0,0c0,15.4-12.5,27.8-27.8,27.8l0,0
                    c-15.4,0-27.8-12.5-27.8-27.8l0,0C6665.4,3698.5,6677.9,3686,6693.2,3686z"/>
                </g>

                <g id="add-circle--button-remove-cross-add-buttons-plus-circle-_-mathematics-math_14_" transform="translate(1143.706 180.401) rotate(45)">
                  <path id="Vector_2_14_" class="st9" d="M7338.6-2109.7v16.9"/>
                  <path id="Vector_3_14_" class="st9" d="M7330.1-2101.3h16.9"/>
                </g>
              </g>
              <text transform="matrix(1 0 0 1 6567.3906 3766.2305)" class="st3 st5 st6">Gevrey-Chambertin</text>
              <text transform="matrix(1 0 0 1 6587.4375 3798.6309)" class="st3 st5 st7">Chemin de la Justice</text>
            </g>
            <g id="pointeur_3_"
               class="pointer scroll-to-zone"
               x="-2095"
               y="-1275"
            >
              <path id="Tracé_701_12_" class="st12" d="M6706.4,3902.1c4.6-6.2,8.9-12.6,12.6-19.4l0.2-0.2c3.8-5.9,5.9-12.9,5.8-19.9
                c0.1-20.2-16.2-36.6-36.3-36.6s-36.6,16.2-36.6,36.3c0,0.1,0,0.2,0,0.3c0.1,3.5,0.6,7,1.4,10.4c1.1,3.3,2.6,6.4,4.4,9.3l0.2,0.4
                c3.8,6.8,8.1,13.3,12.8,19.4c6.8,9.7,13.8,19.4,17.6,30.7C6692.5,3921.4,6699.5,3911.8,6706.4,3902.1L6706.4,3902.1z"/>
              <circle id="Ellipse_11_12_" class="st11" cx="6689.4" cy="3860.9" r="16.6"/>
            </g>
          </g>
          <g id="wine-106"
             transform="translate(0 0)"
             class="pointer element-w-tooltip"
             @mousedown="(event) => handlePointerClick(event, 'wine-106')"
             @touchstart="(event) => handlePointerClick(event, 'wine-106')"
             :class="{ 'element-w-tooltip--is-active': wineStore.selectedWine === 'wine-106' }"
          >
          >
            <g id="label_12_" class="st2">
              <g transform="matrix(1, 0, 0, 1, 0, 0)" class="st3">
                <path id="Rectangle_49-2_12_" class="st4" d="M3524.8,8865.8h262.3c2.8,0,5.1,1.5,5.1,3.5v75.5c0,1.9-2.2,3.5-5.1,3.5h-262.3
                  c-2.8,0-5.1-1.5-5.1-3.5v-75.5C3519.8,8867.4,3522.1,8865.8,3524.8,8865.8z"/>
              </g>
              <g id="btn_12_" transform="translate(-979 -170)" class="st3">
                <g id="Rectangle_46_12_" transform="translate(1126 170)">
                  <path class="st0" d="M3645.1,8836.4L3645.1,8836.4c15.8,0,28.6,12.8,28.6,28.6l0,0c0,15.8-12.8,28.6-28.6,28.6l0,0
                    c-15.8,0-28.6-12.8-28.6-28.6l0,0C3616.5,8849.3,3629.3,8836.4,3645.1,8836.4z"/>
                  <path class="st8" d="M3645.1,8837.2L3645.1,8837.2c15.4,0,27.8,12.5,27.8,27.8l0,0c0,15.4-12.5,27.8-27.8,27.8l0,0
                    c-15.4,0-27.8-12.5-27.8-27.8l0,0C3617.3,8849.7,3629.8,8837.2,3645.1,8837.2z"/>
                </g>

                <g id="add-circle--button-remove-cross-add-buttons-plus-circle-_-mathematics-math_12_" transform="translate(1143.706 180.401) rotate(45)">
                  <path id="Vector_2_12_" class="st9" d="M8825.8,3688.1v16.9"/>
                  <path id="Vector_3_12_" class="st9" d="M8817.3,3696.5h16.9"/>
                </g>
              </g>
              <text transform="matrix(1 0 0 1 3595.3203 8916.9561)" class="st3 st5 st6">Pommard</text>
            </g>
            <g id="pointeur_4_"
               class="pointer scroll-to-zone"
               x="-780"
               y="-3655"
            >
              <path id="Tracé_701_13_" class="st12" d="M3674.6,9019.7c4.6-6.2,8.9-12.6,12.6-19.4l0.2-0.2c3.8-5.9,5.9-12.9,5.8-19.9
              c0.1-20.2-16.2-36.6-36.3-36.6c-20.2-0.1-36.6,16.2-36.6,36.3c0,0.1,0,0.2,0,0.3c0.1,3.5,0.6,7,1.4,10.4c1.1,3.3,2.6,6.4,4.4,9.3
              l0.2,0.4c3.8,6.8,8.1,13.3,12.8,19.4c6.8,9.7,13.8,19.4,17.6,30.7C3660.7,9039,3667.7,9029.4,3674.6,9019.7L3674.6,9019.7z"/>
              <circle id="Ellipse_11_13_" class="st11" cx="3657.6" cy="8978.5" r="16.6"/>
            </g>
          </g>
          <g id="wine-107"
             transform="translate(0 0)"
             class="pointer element-w-tooltip"
             @mousedown="(event) => handlePointerClick(event, 'wine-107')"
             @touchstart="(event) => handlePointerClick(event, 'wine-107')"
             :class="{ 'element-w-tooltip--is-active': wineStore.selectedWine === 'wine-107' }"
          >
            <g id="label_5_" class="st2">
              <g transform="matrix(1, 0, 0, 1, 0, 0)" class="st3">
                <path id="Rectangle_49-2_5_" class="st4" d="M1638.7,11360.3H1901c2.8,0,5.1,2.1,5.1,4.8v104.9c0,2.6-2.2,4.8-5.1,4.8h-262.3
                  c-2.8,0-5.1-2.1-5.1-4.8v-104.9C1633.7,11362.5,1635.9,11360.3,1638.7,11360.3z"/>
              </g>
              <g id="btn_5_" transform="translate(-979 -170)" class="st3">
                <g id="Rectangle_46_5_" transform="translate(1126 170)">
                  <path class="st0" d="M1758.9,11330L1758.9,11330c15.8,0,28.6,12.8,28.6,28.6l0,0c0,15.8-12.8,28.6-28.6,28.6l0,0
                    c-15.8,0-28.6-12.8-28.6-28.6l0,0C1730.3,11342.9,1743.1,11330,1758.9,11330z"/>
                  <path class="st8" d="M1758.9,11330.8L1758.9,11330.8c15.4,0,27.8,12.5,27.8,27.8l0,0c0,15.4-12.5,27.8-27.8,27.8l0,0
                    c-15.4,0-27.8-12.5-27.8-27.8l0,0C1731.1,11343.3,1743.6,11330.8,1758.9,11330.8z"/>
                </g>

                <g id="add-circle--button-remove-cross-add-buttons-plus-circle-_-mathematics-math_5_" transform="translate(1143.706 180.401) rotate(45)">
                  <path id="Vector_2_5_" class="st9" d="M9255.3,6785v16.9"/>
                  <path id="Vector_3_5_" class="st9" d="M9246.8,6793.4h16.9"/>
                </g>
              </g>
              <text transform="matrix(1 0 0 1 1657.005 11411.0293)" class="st3 st5 st6">Maranges 1er Cru</text>
              <text transform="matrix(1 0 0 1 1684.8451 11443.4297)" class="st3 st5 st7">Clos des Loyères</text>
            </g>
            <g id="pointeur_10_"
               class="pointer scroll-to-zone"
               x="0"
               y="-4660"
            >
              <path id="Tracé_701_9_" class="st12" d="M1786.6,11543.6c4.6-6.2,8.9-12.6,12.6-19.4l0.2-0.2c3.8-5.9,5.9-12.9,5.8-19.9
              c0.1-20.2-16.2-36.6-36.3-36.6c-20.2-0.1-36.6,16.2-36.6,36.3c0,0.1,0,0.2,0,0.3c0.1,3.5,0.6,7,1.4,10.4c1.1,3.3,2.6,6.4,4.4,9.3
              l0.2,0.4c3.8,6.8,8.1,13.3,12.8,19.4c6.8,9.7,13.8,19.4,17.6,30.7C1772.7,11562.9,1779.7,11553.3,1786.6,11543.6L1786.6,11543.6z
              "/>
              <circle id="Ellipse_11_9_" class="st11" cx="1769.6" cy="11502.4" r="16.6"/>
            </g>
          </g>
          <g id="wine-108"
             transform="translate(0 0)"
             class="pointer element-w-tooltip"
             @mousedown="(event) => handlePointerClick(event, 'wine-108')"
             @touchstart="(event) => handlePointerClick(event, 'wine-108')"
             :class="{ 'element-w-tooltip--is-active': wineStore.selectedWine === 'wine-108' }"
          >
            <g id="label_4_" class="st2">
              <g transform="matrix(1, 0, 0, 1, 0, 0)" class="st3">
                <path id="Rectangle_49-2_4_" class="st4" d="M1582.5,11293.2h262.3c2.8,0,5.1,2.1,5.1,4.8v104.9c0,2.6-2.2,4.8-5.1,4.8h-262.3
                  c-2.8,0-5.1-2.1-5.1-4.8V11298C1577.5,11295.4,1579.7,11293.2,1582.5,11293.2z"/>
              </g>
              <g id="btn_4_" transform="translate(-979 -170)" class="st3">
                <g id="Rectangle_46_4_" transform="translate(1126 170)">
                  <path class="st0" d="M1702.7,11262.9L1702.7,11262.9c15.8,0,28.6,12.8,28.6,28.6l0,0c0,15.8-12.8,28.6-28.6,28.6l0,0
                    c-15.8,0-28.6-12.8-28.6-28.6l0,0C1674.1,11275.8,1686.9,11262.9,1702.7,11262.9z"/>
                  <path class="st8" d="M1702.7,11263.7L1702.7,11263.7c15.4,0,27.8,12.5,27.8,27.8l0,0c0,15.4-12.5,27.8-27.8,27.8l0,0
                    c-15.4,0-27.8-12.5-27.8-27.8l0,0C1674.9,11276.2,1687.4,11263.7,1702.7,11263.7z"/>
                </g>

                <g id="add-circle--button-remove-cross-add-buttons-plus-circle-_-mathematics-math_4_" transform="translate(1143.706 180.401) rotate(45)">
                  <path id="Vector_2_4_" class="st9" d="M9168.1,6777.3v16.9"/>
                  <path id="Vector_3_4_" class="st9" d="M9159.6,6785.7h16.9"/>
                </g>
              </g>
              <text transform="matrix(1 0 0 1 1600.7994 11343.9297)" class="st3 st5 st6">Maranges 1er Cru</text>
              <text transform="matrix(1 0 0 1 1645.7679 11376.3301)" class="st3 st5 st7">Clos des Rois</text>
            </g>
            <g id="pointeur_11_"
               class="pointer scroll-to-zone"
               x="0"
               y="-4660"
            >
              <path id="Tracé_701_8_" class="st12" d="M1728.7,11478.5c4.6-6.2,8.9-12.6,12.6-19.4l0.2-0.2c3.8-5.9,5.9-12.9,5.8-19.9
              c0.1-20.2-16.2-36.6-36.3-36.6c-20.2-0.1-36.6,16.2-36.6,36.3c0,0.1,0,0.2,0,0.3c0.1,3.5,0.6,7,1.4,10.4c1.1,3.3,2.6,6.4,4.4,9.3
              l0.2,0.4c3.8,6.8,8.1,13.3,12.8,19.4c6.8,9.7,13.8,19.4,17.6,30.7C1714.8,11497.9,1721.7,11488.2,1728.7,11478.5L1728.7,11478.5z
              "/>
              <circle id="Ellipse_11_8_" class="st11" cx="1711.6" cy="11437.3" r="16.6"/>
            </g>
          </g>
          <g id="wine-109"
             transform="translate(0 0)"
             class="pointer element-w-tooltip"
             @mousedown="(event) => handlePointerClick(event, 'wine-109')"
             @touchstart="(event) => handlePointerClick(event, 'wine-109')"
             :class="{ 'element-w-tooltip--is-active': wineStore.selectedWine === 'wine-109' }"
          >
            <g id="label_6_" class="st2">
              <g transform="matrix(1, 0, 0, 1, 0, 0)" class="st3">
                <path id="Rectangle_49-2_6_" class="st4" d="M1717.9,11377.6h262.3c2.8,0,5.1,2.1,5.1,4.8v104.9c0,2.6-2.2,4.8-5.1,4.8h-262.3
                  c-2.8,0-5.1-2.1-5.1-4.8v-104.9C1712.9,11379.8,1715.1,11377.6,1717.9,11377.6z"/>
              </g>
              <g id="btn_6_" transform="translate(-979 -170)" class="st3">
                <g id="Rectangle_46_6_" transform="translate(1126 170)">
                  <path class="st0" d="M1838.1,11347.3L1838.1,11347.3c15.8,0,28.6,12.8,28.6,28.6l0,0c0,15.8-12.8,28.6-28.6,28.6l0,0
                    c-15.8,0-28.6-12.8-28.6-28.6l0,0C1809.5,11360.2,1822.3,11347.3,1838.1,11347.3z"/>
                  <path class="st8" d="M1838.1,11348.1L1838.1,11348.1c15.4,0,27.8,12.5,27.8,27.8l0,0c0,15.4-12.5,27.8-27.8,27.8l0,0
                    c-15.4,0-27.8-12.5-27.8-27.8l0,0C1810.3,11360.6,1822.8,11348.1,1838.1,11348.1z"/>
                </g>

                <g id="add-circle--button-remove-cross-add-buttons-plus-circle-_-mathematics-math_6_" transform="translate(1143.706 180.401) rotate(45)">
                  <path id="Vector_2_6_" class="st9" d="M9323.5,6741.3v16.9"/>
                  <path id="Vector_3_6_" class="st9" d="M9315,6749.7h16.9"/>
                </g>
              </g>
              <text transform="matrix(1 0 0 1 1736.2021 11428.3311)" class="st3 st5 st6">Maranges 1er Cru</text>
              <text transform="matrix(1 0 0 1 1757.323 11460.7314)" class="st3 st5 st7">Clos des Roussots</text>
            </g>
            <g id="pointeur_9_"
               class="pointer scroll-to-zone"
               x="-98"
               y="-4659"
            >
              <path id="Tracé_701_10_" class="st12" d="M1866.6,11563.3c4.6-6.2,8.9-12.6,12.6-19.4l0.2-0.2c3.8-5.9,5.9-12.9,5.8-19.9
              c0.1-20.2-16.2-36.6-36.3-36.6c-20.2-0.1-36.6,16.2-36.6,36.3c0,0.1,0,0.2,0,0.3c0.1,3.5,0.6,7,1.4,10.4c1.1,3.3,2.6,6.4,4.4,9.3
              l0.2,0.4c3.8,6.8,8.1,13.3,12.8,19.4c6.8,9.7,13.8,19.4,17.6,30.7C1852.7,11582.6,1859.6,11573,1866.6,11563.3L1866.6,11563.3z"
              />
              <circle id="Ellipse_11_10_" class="st11" cx="1849.5" cy="11522.1" r="16.6"/>
            </g>
          </g>
          <g id="wine-111"
             transform="translate(0 0)"
             class="pointer element-w-tooltip"
             @mousedown="(event) => handlePointerClick(event, 'wine-111')"
             @touchstart="(event) => handlePointerClick(event, 'wine-111')"
             :class="{ 'element-w-tooltip--is-active': wineStore.selectedWine === 'wine-111' }"
          >
            <g id="label_10_" class="st2">
              <g transform="matrix(1, 0, 0, 1, 0, 0)" class="st3">
                <path id="Rectangle_49-2_10_" class="st4" d="M2363.3,9211.9h262.3c2.8,0,5.1,2.1,5.1,4.8v104.9c0,2.6-2.2,4.8-5.1,4.8h-262.3
                  c-2.8,0-5.1-2.1-5.1-4.8v-104.9C2358.3,9214.1,2360.6,9211.9,2363.3,9211.9z"/>
              </g>
              <g id="btn_10_" transform="translate(-979 -170)" class="st3">
                <g id="Rectangle_46_10_" transform="translate(1126 170)">
                  <path class="st0" d="M2483.6,9181.6L2483.6,9181.6c15.8,0,28.6,12.8,28.6,28.6l0,0c0,15.8-12.8,28.6-28.6,28.6l0,0
                    c-15.8,0-28.6-12.8-28.6-28.6l0,0C2455,9194.5,2467.8,9181.6,2483.6,9181.6z"/>
                  <path class="st8" d="M2483.6,9182.4L2483.6,9182.4c15.4,0,27.8,12.5,27.8,27.8l0,0c0,15.4-12.5,27.8-27.8,27.8l0,0
                    c-15.4,0-27.8-12.5-27.8-27.8l0,0C2455.8,9194.9,2468.3,9182.4,2483.6,9182.4z"/>
                </g>

                <g id="add-circle--button-remove-cross-add-buttons-plus-circle-_-mathematics-math_10_" transform="translate(1143.706 180.401) rotate(45)">
                  <path id="Vector_2_10_" class="st9" d="M8248.5,4753.4v16.9"/>
                  <path id="Vector_3_10_" class="st9" d="M8240,4761.8h16.9"/>
                </g>
              </g>
              <text transform="matrix(1 0 0 1 2409.6892 9262.6035)" class="st3 st5 st6">Saint-Romain</text>
              <text transform="matrix(1 0 0 1 2424.7856 9295.0039)" class="st3 st5 st7">Sous Château</text>
            </g>
            <g id="pointeur_14_"
               class="pointer scroll-to-zone"
               x="-409"
               y="-4026"
            >
              <path id="Tracé_701_3_" class="st12" d="M2513.2,9395.2c4.6-6.2,8.9-12.6,12.6-19.4l0.2-0.2c3.8-5.9,5.9-12.9,5.8-19.9
              c0.1-20.2-16.2-36.6-36.3-36.6c-20.2-0.1-36.6,16.2-36.6,36.3c0,0.1,0,0.2,0,0.3c0.1,3.5,0.6,7,1.4,10.4c1.1,3.3,2.6,6.4,4.4,9.3
              l0.2,0.4c3.8,6.8,8.1,13.3,12.8,19.4c6.8,9.7,13.8,19.4,17.6,30.7C2499.3,9414.5,2506.3,9404.9,2513.2,9395.2L2513.2,9395.2z"/>
              <circle id="Ellipse_11_4_" class="st11" cx="2496.2" cy="9354" r="16.6"/>
            </g>
          </g>
          <g id="wine-112"
             transform="translate(0 0)"
             class="pointer element-w-tooltip"
             @mousedown="(event) => handlePointerClick(event, 'wine-112')"
             @touchstart="(event) => handlePointerClick(event, 'wine-112')"
             :class="{ 'element-w-tooltip--is-active': wineStore.selectedWine === 'wine-112' }"
          >
          >
            <g id="label_3_" class="st2">
              <g transform="matrix(1, 0, 0, 1, 0, 0)" class="st3">
                <path id="Rectangle_49-2_3_" class="st4" d="M1802.9,11154h246.3c2.6,0,4.8,2.1,4.8,4.8v104.9c0,2.6-2.1,4.8-4.8,4.8h-246.3
                  c-2.6,0-4.8-2.1-4.8-4.8v-104.9C1798.2,11156.2,1800.3,11154,1802.9,11154z"/>
              </g>
              <g id="btn_3_" transform="translate(-979 -170)" class="st3">
                <g id="Rectangle_46_3_" transform="translate(1126 170)">
                  <path class="st0" d="M1908.9,11123.7L1908.9,11123.7c15.8,0,28.6,12.8,28.6,28.6l0,0c0,15.8-12.8,28.6-28.6,28.6l0,0
                    c-15.8,0-28.6-12.8-28.6-28.6l0,0C1880.3,11136.6,1893.1,11123.7,1908.9,11123.7z"/>
                  <path class="st8" d="M1908.9,11124.5L1908.9,11124.5c15.4,0,27.8,12.5,27.8,27.8l0,0c0,15.4-12.5,27.8-27.8,27.8l0,0
                    c-15.4,0-27.8-12.5-27.8-27.8l0,0C1881.1,11137,1893.6,11124.5,1908.9,11124.5z"/>
                </g>
                <g id="add-circle--button-remove-cross-add-buttons-plus-circle-_-mathematics-math_3_" transform="translate(1143.706 180.401) rotate(45)">
                  <path id="Vector_2_3_" class="st9" d="M9215.4,6533.1v16.9"/>
                  <path id="Vector_3_3_" class="st9" d="M9206.9,6541.5h16.9"/>
                </g>
              </g>
              <text transform="matrix(1 0 0 1 1866.9418 11203.7178)" class="st3 st5 st6">Santenay</text>
              <text transform="matrix(1 0 0 1 1870.4498 11235.1182)" class="st3 st5 st7">Le Chainey</text>
            </g>
            <g id="pointeur_8_"
               class="pointer scroll-to-zone"
               x="-265"
               y="-4660"
            >
             <path id="Tracé_701_2_" class="st12" d="M1943.1,11340c4.6-6.2,8.9-12.6,12.6-19.4l0.2-0.2c3.8-5.9,5.9-12.9,5.8-19.9
              c0.1-20.2-16.2-36.6-36.3-36.6c-20.2-0.1-36.6,16.2-36.6,36.3c0,0.1,0,0.2,0,0.3c0.1,3.5,0.6,7,1.4,10.4c1.1,3.3,2.6,6.4,4.4,9.3
              l0.2,0.4c3.8,6.8,8.1,13.3,12.8,19.4c6.8,9.7,13.8,19.4,17.6,30.7C1929.2,11359.4,1936.1,11349.7,1943.1,11340L1943.1,11340z"/>
              <circle id="Ellipse_11_3_" class="st11" cx="1926.1" cy="11298.8" r="16.6"/>
            </g>
          </g>
          <g id="wine-736"
             transform="translate(0 0)"
             class="pointer element-w-tooltip"
             @mousedown="(event) => handlePointerClick(event, 'wine-736')"
             @touchstart="(event) => handlePointerClick(event, 'wine-736')"
             :class="{ 'element-w-tooltip--is-active': wineStore.selectedWine === 'wine-736' }"
          >
            <g id="label_15_" class="st2">
              <g transform="matrix(1, 0, 0, 1, 0, 0)" class="st1">
                <path id="Rectangle_49-2_15_" class="st4" d="M1433.6,11512.4h316.8c3.3,0,6.2,1.6,6.2,3.6v79.3c0,2-2.7,3.6-6.2,3.6h-316.8
                  c-3.3,0-6.2-1.6-6.2-3.6v-79.3C1427.5,11514.1,1430.2,11512.4,1433.6,11512.4z"/>
              </g>
              <text transform="matrix(1 0 0 1 1477.3003 11563.6182)" class="st3 st5 st6">Bourgogne Aligoté</text>
              <g id="btn_15_" transform="translate(-979 -170)" class="st3">
                <g id="Rectangle_46_15_" transform="translate(1126 170)">
                  <path class="st0" d="M1610.6,11482.6L1610.6,11482.6c15.8,0,28.6,12.8,28.6,28.6l0,0c0,15.8-12.8,28.6-28.6,28.6l0,0
                    c-15.8,0-28.6-12.8-28.6-28.6l0,0C1582,11495.5,1594.8,11482.6,1610.6,11482.6z"/>
                  <path class="st8" d="M1610.6,11483.4L1610.6,11483.4c15.4,0,27.8,12.5,27.8,27.8l0,0c0,15.4-12.5,27.8-27.8,27.8l0,0
                    c-15.4,0-27.8-12.5-27.8-27.8l0,0C1582.8,11495.9,1595.3,11483.4,1610.6,11483.4z"/>
                </g>

                <g id="add-circle--button-remove-cross-add-buttons-plus-circle-_-mathematics-math_15_" transform="translate(1143.706 180.401) rotate(45)">
                  <path id="Vector_2_15_" class="st9" d="M9258.4,6997.8v16.9"/>
                  <path id="Vector_3_15_" class="st9" d="M9249.9,7006.2h16.9"/>
                </g>
              </g>
            </g>
            <g
                id="pointeur_15_"
                class="pointer scroll-to-zone"
                x="0"
                y="-4660"
            >
              <path id="Tracé_701_16_" class="st10" d="M1609.9,11662.7c4.6-6.2,8.9-12.6,12.6-19.4l0.2-0.2c3.8-5.9,5.9-12.9,5.8-19.9
                c0.1-20.2-16.2-36.6-36.3-36.6c-20.2-0.1-36.6,16.2-36.6,36.3c0,0.1,0,0.2,0,0.3c0.1,3.5,0.6,7,1.4,10.4c1.1,3.3,2.6,6.4,4.4,9.3
                l0.2,0.4c3.8,6.8,8.1,13.3,12.8,19.4c6.8,9.7,13.8,19.4,17.6,30.7C1596,11682.1,1602.9,11672.4,1609.9,11662.7L1609.9,11662.7z"
              />
              <circle id="Ellipse_11_16_" class="st11" cx="1592.8" cy="11621.5" r="16.6"/>
            </g>
          </g>
          <g id="chateau"
             transform="translate(0 0)"
             class="pointer scroll-to-zone element-w-tooltip"
             @mousedown="(event) => handlePointerClick(event, 'chateau')"
             @touchstart="(event) => handlePointerClick(event, 'chateau')"
          >
            <path id="Tracé_701_4_" class="st13" d="M2465.7,9763.8c4.6-6.2,8.9-12.6,12.6-19.4l0.2-0.2c3.8-5.9,5.9-12.9,5.8-19.9
            c0.1-20.2-16.2-36.6-36.3-36.6c-20.2-0.1-36.6,16.2-36.6,36.3c0,0.1,0,0.2,0,0.3c0.1,3.5,0.6,7,1.4,10.4c1.1,3.3,2.6,6.4,4.4,9.3
            l0.2,0.4c3.8,6.8,8.1,13.3,12.8,19.4c6.8,9.7,13.8,19.4,17.6,30.7C2451.8,9783.1,2458.8,9773.4,2465.7,9763.8L2465.7,9763.8z"/>
            <circle id="Ellipse_11_5_" class="st0" cx="2448.7" cy="9722.5" r="16.6"/>
          </g>
        </g>
  </svg>
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref, reactive, watch} from 'vue';
import Panzoom from '@panzoom/panzoom';
import {wineStore} from "@/stores/wineStore";
import {castleStore} from '@/stores/castleStore';
import imagePath from '@/assets/img/wines/cote-nuits-beaune-v2.webp';

const imgPath = ref(imagePath);
const openStates = reactive({});
let panzoom;
let pointerList;

const handlePointerClick = (event, elementId) => {

  event.preventDefault();

  if (elementId === "chateau") {
    wineStore.resetSelectedWine();
    castleStore.toggleShowCastleItem();
  } else {
    castleStore.setShowCastleItem(false);

    wineStore.selectedWine && wineStore.selectedWine === elementId ? wineStore.resetSelectedWine() : wineStore.setSelectedWine(elementId);

    if (openStates[elementId] === undefined) {
      openStates[elementId] = true;
    } else {
      openStates[elementId] = !openStates[elementId];
    }
  }

}

onMounted(() => {
  imgPath.value;

  const area = document.querySelector('.svg-container');
  panzoom = Panzoom(area, {
    initialZoom: 1,
    startTransform: 'scale(1)',
    increment: 1,
    zoomScaleSensitivity: 10,
    minScale: 1,
    maxScale: 5,
    contain: 'outside',
    zoomDoubleClickSpeed: 1,
    exclude: [document.querySelectorAll('.pointer')],
    excludeClass: 'pointer',
    startX: -666,
    startY: -3964
  });


  panzoom.panWithWheel = true;
  panzoom.zoomWithWheel = false;
  panzoom.zoomWithTouch = true;

  pointerList = document.querySelectorAll('.scroll-to-zone');
});

watch(
    () => wineStore.selectedWine,
    (wineId) => {
      pointerList.forEach((pointer) => {
        if (pointer.closest(`#${wineId}`)) {
          moveMapToPointer(pointer);
        }
      });
    }
);

const moveMapToPointer = (pointer) => {

  const targetX = parseFloat(pointer.getAttribute('x'));
  const targetY = parseFloat(pointer.getAttribute('y'));

  setTimeout(() => {
    if (panzoom) {
      const currentPan = panzoom.getPan();
      const startX = currentPan.x;
      const startY = currentPan.y;

      const duration = 500;
      const startTime = performance.now();

      const animatePan = (currentTime) => {
        const elapsedTime = currentTime - startTime;
        const progress = Math.min(elapsedTime / duration, 1);

        const newX = startX + (targetX - startX) * progress;
        const newY = startY + (targetY - startY) * progress;

        panzoom.pan(newX, newY);

        if (progress < 1) {
          requestAnimationFrame(animatePan);
        }
      };

      requestAnimationFrame(animatePan);
    }
  }, 200);

}

</script>

<style scoped>

.svg-container {
  position: relative;
  left: 30%;
  overflow: hidden;
  width: 4000px !important;
  height: 5560px !important;
}

.svg-container image {
  width: 100%;
  height: 100%;
  display: block;
}

.wines-map-container {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.buttons {
  position: absolute;
  z-index: 10;
  bottom: 30px;
  right: 25px;
  display: flex;
  gap: 10px;
}

.buttons button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.element-w-tooltip--is-active .st2 {
  display: inline;
}

.pointer {
  cursor: pointer;
}

.st0 {
  fill: #F8F7F4;
}

.st1 {
  fill: #565049;
}

.st2 {
  display: none;
}

.st3 {
  display: inline;
}

.st4 {
  fill: #FFFFFF;
}

.st5 {
  font-family: 'ArialMT';
}

.st6 {
  font-size: 28px;
}

.st7 {
  font-size: 22px;
}

.st8 {
  fill: none;
  stroke: #D2D1CC;
}

.st9 {
  fill: none;
  stroke: #000000;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.st10 {
  fill: #A1822E;
}

.st11 {
  fill: #FCFBFA;
}

.st12 {
  fill: #741E1E;
}

.st13 {
  fill: #2A2721;
}

</style>
