import { reactive } from 'vue';

export const wineStore = reactive({
	selectedWine: null,
	setSelectedWine(wineId) {
		this.selectedWine = wineId;
	},
	resetSelectedWine() {
		this.selectedWine = null;
	}
});