<template>
  <div :class="[props.type + '-wines-container']">
    <div :class="[props.type + '-wines-title-container']">
      <h2 :class="[props.type + '-wines-title', 'wines-title']">{{ props.title }}</h2>
    </div>
    <div class="wine-items-container">
      <ul class="wines-list">
        <li class="wine-item" :class="[wine.id]" v-for="wine in wines" :key="wine.name" @click="wineStore.setSelectedWine(wine.id)">
          {{ wine.name }}
          <svg xmlns="http://www.w3.org/2000/svg" width="5.788" height="9.455" viewBox="0 0 5.788 9.455">
            <g transform="translate(56.727 51.894) rotate(180)">
              <line y1="3.667" x2="3.667" transform="translate(52 43.5)" fill="none" stroke="#a2a09b" stroke-linecap="round" stroke-width="1.5"/>
              <line y1="3.667" x2="3.667" transform="translate(55.667 47.167) rotate(90)" fill="none" stroke="#a2a09b" stroke-linecap="round" stroke-width="1.5"/>
            </g>
          </svg>
        </li>
      </ul>
    </div>
  </div>
  <Transition name="flip">
    <WineDetails
        v-if="selectedWineData"
        :wine="selectedWineData"
        @close="handleCloseDetails"
        :class="{ 'wine-details-container--is-shown': selectedWineData }"
    />
  </Transition>
</template>

<script setup>

import {computed, defineProps} from 'vue';
import {wineStore} from "@/stores/wineStore";
import WineDetails from "@/components/wines/WineDetails.vue";

const props = defineProps({
  type: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true
  },
  wines: {
    type: Array,
    required: true
  }
});


const selectedWineData = computed(() => {
  return props.wines.find((wine) => wine.id === wineStore.selectedWine)
});

const handleCloseDetails = () => {
  wineStore.resetSelectedWine();
}
</script>

<style scoped>

.wine-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  color: #A2A09B;
  line-height: 40px;
  border-top: 1px solid #E5E5E5;
}

.wines-list li:last-of-type {
  border-bottom: 1px solid #E5E5E5;;
}

.wines-title {
  margin: 18px 0;
}

.white-wines-title {
  margin-top: 0;
}

.wines-title {
  font-family: var(--secondary-font);
  font-size: 42px;
  font-weight: 400;
}



</style>