import "./assets/css/normalize.css";
import "./assets/css/vars.css";
import "./assets/css/style.css";
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import noRightClick from './directives/no-right-click';
import { languageStore } from './stores/languageStore';

const app = createApp(App);

const storedLanguage = localStorage.getItem('language');
if (storedLanguage) {
	languageStore.setLang(storedLanguage);
}

app.directive('no-right-click', noRightClick);
app.use(router);
app.mount('#app');