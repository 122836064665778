<template>
  <div class="cycle-month-item-container month-item-element">
    <div class="cycle-month-background-container"></div>
    <div class="cycle-month-img-icon-container month-element">
      <div class="cycle-month-img-container">
        <img :src="props.monthCard.image ? getImageSrc(props.monthCard.image) : ''" :alt="props.monthCard.title.french" class="cycle-month-img">
      </div>
      <div class="weather-risk-container" v-for="(button, buttonIndex) in getIcon" :key="buttonIndex">
        <img :src="getImageSrc(button.icon)" :alt="props.monthCard.title[languageStore.lang]" class="weather-risk-icon">
      </div>
    </div>
    <div class="cycle-month-title-container month-element">
      <h2 class="cycle-month-title">{{ props.monthCard.title[languageStore.lang] }}</h2>
    </div>
    <div class="cycle-month-description-container month-element">
      <p>{{ props.monthCard.description[languageStore.lang] }}</p>
    </div>
    <div class="cycle-month-btns-container month-element">
      <button 
          :class="[button.style, 'cycle-month-btn']"
          v-for="(button, buttonIndex) in props.monthCard.buttonList" 
          :key="buttonIndex"
          @click="() => handleButtonClick(buttonIndex)"
      >{{ button.text[languageStore.lang] }}</button>
    </div>
  </div>
</template>

<script setup>

import {defineProps, computed, defineEmits} from "vue";
import {languageStore} from "@/stores/languageStore";

const props = defineProps({
  monthCard: Object,
  monthIndex: Number
});

const emit = defineEmits(['button-click']);

const handleButtonClick = (buttonIndex) => {
  emit('button-click', {
    monthIndex: props.monthIndex,
    buttonIndex: buttonIndex
  });
}
const getImageSrc = (image) => {
  if (image) {
    return require(`@/assets/img/cycle/months/${image}`);
  } else {
    return '';
  }
};

const getIcon = computed(() => {
  return props.monthCard.buttonList.filter(button => button.icon);
});

</script>

<style scoped>

.month-item-element {
  width: 370px;
}

.month-element {
  grid-column: 1/-1;
}

.cycle-month-item-container.month-item-element {
  display: grid;
  grid-template-rows: 50px 125px 70px 150px 105px;
}

.cycle-month-background-container {
  grid-column: 1/-1;
  grid-row: 2/6;
  background: #fff;
  border-radius: 3px;
}

.cycle-month-img-icon-container {
  position: relative;
  display: flex;
  justify-content: center;
  grid-row: 1/3;
}

.cycle-month-img-icon-container .cycle-month-img {
  border-radius: 100%;
  max-width: 180px;
}

.weather-risk-container {
  position: absolute;
  bottom: 0;
  right: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--tertiary-color);
  border-radius: 50%;
  width: 58px;
  height: 58px;
}

.cycle-month-title-container {
  grid-row: 3/4;
}

.cycle-month-title-container h2 {
  font-size: 27px;
  font-weight: 500;
  text-align: center;
}

.cycle-month-description-container {
  display: flex;
  justify-content: center;
  grid-row: 4/5;
}

.cycle-month-description-container p {
  color: #8F8D88;
  text-align: center;
  max-width: 280px;
}

.cycle-month-btns-container {
  grid-row: 5/6;
}

.cycle-month-btns-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.cycle-month-btn {
  background: #fff;
  border-radius: 50px;
  padding: 12px 33px;
}

.primary {
  font-weight: 500;
  color: #000;
  border: 1px solid #000;
}

.secondary {
  background: var(--tertiary-color);
  color: #fff;
  border: 1px solid var(--tertiary-color);
}

</style>