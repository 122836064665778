<template>
  <div class="container-inner">
    <KeepAlive>
      <WinesMap :wines="wines" :selectedWineData="selectedWineData"/>
    </KeepAlive>
    <WinesList/>
    <Transition name="flip">
      <CastleItem v-if="showCastleItem"></CastleItem>
    </Transition>
  </div>
</template>

<script setup>

import WinesList from "@/components/wines/WinesList.vue";
import WinesMap from "@/components/wines/WinesMap.vue";
import {computed} from "vue";
import {castleStore} from "@/stores/castleStore";
import CastleItem from "@/components/wines/CastleItem.vue";

const showCastleItem = computed(() => castleStore.showCastleItem);

</script>

<style scoped>

.container-inner {
  position: relative;
}

</style>