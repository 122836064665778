<template>

  <div class="wine-details-container">
    <div
        class="wine-details-left-container"
        :class="{ 'wine-details-left-container--is-full-size': isFullSize }"
    >
      <div
          class="wine-details-img-container"
          :class="{ 'wine-details-img-container--is-full-size': isFullSize }"
      >
        <img :src="fullImageUrl"
             :alt="props.wine.title"
             :class="{ 'wine-details-img--is-full-size': isFullSize }">
        <button class="wine-details-full-size-btn" @click="toggleFullSize">
          <svg xmlns="http://www.w3.org/2000/svg" width="28.101" height="27.101" viewBox="0 0 28.101 27.101">
            <g id="Groupe_1211" data-name="Groupe 1211" transform="translate(-444 -331)">
              <g id="add-circle--button-remove-cross-add-buttons-plus-circle-_-mathematics-math" data-name="add-circle--button-remove-cross-add-buttons-plus-circle-+-mathematics-math" transform="translate(471.346 329.346) rotate(90)">
                <path id="Vector_2" d="M7,4v9.692" transform="translate(7.5 5.654)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <path id="Vector_3" d="M4,7h9.692" transform="translate(5.654 7.5)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              </g>
              <g id="Ellipse_13" data-name="Ellipse 13" transform="translate(444 331)" fill="none" stroke="#000" stroke-width="1.7">
                <circle cx="12.5" cy="12.5" r="12.5" stroke="none"/>
                <circle cx="12.5" cy="12.5" r="11.65" fill="none"/>
              </g>
              <line id="Ligne_402" data-name="Ligne 402" x2="6" y2="6" transform="translate(465.5 351.5)" fill="none" stroke="#000" stroke-width="1.7"/>
            </g>
          </svg>
        </button>
      </div>
      <div class="wine-details-features-container">
<!--        <div class="wine-details-awards-container wine-details-item" v-if="props.wine.awards && props.wine.awards.some(award => !!award)">-->
<!--          <span class="wine-details-feature-title">{{ translations.recompenses[languageStore.lang] }}</span>-->
<!--          <ul>-->
<!--            <li v-for="(award, awardIndex) in props.wine.awards.slice(0, 3)" :key="awardIndex">{{ award }}</li>-->
<!--          </ul>-->
<!--        </div>-->
        <div class="wine-details-origins-container wine-details-item">
          <span class="wine-details-feature-title">{{ translations.origines[languageStore.lang] }}</span>
          <ul>
            <li>{{ translations.cepage[languageStore.lang] }} : {{ props.wine.cepage }}</li>
            <li>{{ translations.sol[languageStore.lang] }} : {{ props.wine.sol[languageStore.lang] }}</li>
            <li>{{ translations.taille[languageStore.lang] }} : {{ props.wine.taille[languageStore.lang] }}</li>
            <li>{{ translations.rendement[languageStore.lang] }} : {{ props.wine.rendement }}</li>
          </ul>
        </div>
        <div class="wine-origins-vinification-container wine-details-item">
          <span class="wine-details-feature-title">{{ translations.vinification[languageStore.lang] }}</span>
          <ul>
            <li>{{ props.wine.vinification[languageStore.lang] }}</li>
          </ul>
        </div>
        <div class="wine-origins-elevage-container wine-details-item">
          <span class="wine-details-feature-title">{{ translations.elevage[languageStore.lang] }}</span>
          <ul>
            <li>{{ props.wine.elevage[languageStore.lang] }}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="wine-details-right-container">
      <h1 v-html="renderTitle"></h1>
      <button class="wine-details-close-btn" @click="closeDetails">
        <svg xmlns="http://www.w3.org/2000/svg" width="15.339" height="15.339" viewBox="0 0 15.339 15.339">
          <g id="add-circle--button-remove-cross-add-buttons-plus-circle-_-mathematics-math" data-name="add-circle--button-remove-cross-add-buttons-plus-circle-+-mathematics-math" transform="translate(7.669 -18.494) rotate(45)">
            <path id="Vector_2" d="M7,4V21.692" transform="translate(11.5 5.654)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            <path id="Vector_3" d="M4,7H21.692" transform="translate(5.654 11.5)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
          </g>
        </svg>
      </button>
      <div class="wine-origins-appreciation-container wine-details-item">
        <span class="wine-details-feature-title">{{ translations.appreciation[languageStore.lang] }}</span>
        <ul>
          <li>{{ props.wine.appreciation[languageStore.lang] }}</li>
        </ul>
      </div>
      <div class="wine-origins-conservation-container wine-details-item">
        <span class="wine-details-feature-title">{{ translations.conservation[languageStore.lang] }}</span>
        <ul>
          <li>{{ props.wine.conservation[languageStore.lang] }}</li>
        </ul>
      </div>
      <div class="wine-origins-gastronomie-container wine-details-item">
        <span class="wine-details-feature-title">{{ translations.gastronomie[languageStore.lang] }}</span>
        <ul>
          <li>{{ props.wine.gastronomie[languageStore.lang] }}</li>
        </ul>
      </div>
      <div class="wine-origins-description-container">
        <ul>
          <li>{{ props.wine.description }}</li>
        </ul>
      </div>
    </div>
  </div>

</template>

<script setup>

import {defineProps, ref, defineEmits, computed} from "vue";
import {languageStore} from "@/stores/languageStore";
import translations from '@/data/translations.json';

const props = defineProps({
    wine: Object
});

const isFullSize = ref(null);

const toggleFullSize = () => {
  isFullSize.value = !isFullSize.value;
};

const emit = defineEmits(['close']);

const closeDetails = () => {
  emit('close');
}

const fullImageUrl = `${props.wine.img}`;

const renderTitle = computed(() => props.wine.custom_title ? props.wine.custom_title : props.wine.name);

</script>

<style scoped>

/*Main*/

.wine-details-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  width: 930px;
  background: #fff;
}

/*Common*/

.wine-details-feature-title {
  font-size: 15px;
  font-weight: 600;
}

.wine-details-container ul {
  padding-top: 5px;
}

.wine-details-container li {
  color: #8F8D88;
  line-height: 25px;
}

.wine-details-item {
  padding-bottom: 25px;
}

/*Left*/

.wine-details-left-container {
  background: #E9E8E1;
  border: 1px solid #E0DDD3;
  border-top-left-radius: 3px;
  transition: all 250ms ease-in-out;
  width: 310px;
}

.wine-details-left-container--is-full-size {
  width: 410px;
}

.wine-details-img-container {
  position: relative;
  display: flex;
  justify-content: center;
  height: 285px;
  transition: all 350ms ease-in-out;
}

.wine-details-img-container--is-full-size {
  transition: all 350ms ease-in-out;
  height: 850px;
}

.wine-details-img-container img:not(.wine-details-img--is-full-size) {
  transition: all 350ms ease-in-out;
  height: 320px;
  margin-top: -20px;
}

.wine-details-img--is-full-size {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #E9E8E1;
  object-fit: contain;
  transition: all 350ms ease-in-out;
  width: 100%;
  height: 810px;
  padding-top: 35px;
}

.wine-details-full-size-btn {
  position: absolute;
  top: 20px;
  right: 10px;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 60px;
  height: 60px;
  padding: 5px 10px;
}

.wine-details-img-container--is-full-size .wine-details-full-size-btn path#Vector_3 {
  display: none;
}

.wine-details-features-container {
  width: 185px;
  padding: 35px 0 0 65px;
}

.wine-details-left-container--is-full-size .wine-details-features-container {
  display: none;
}

.wine-details-left-container li {
  font-size: 15px;
}

/*Right*/

.wine-details-right-container {
  width: 445px;
  padding: 65px 95px 60px 85px;
}

.wine-details-right-container h1 {
  font-size: 40px;
  padding-bottom: 65px;
  margin: 0;
}

.wine-details-right-container li {
  font-size: 16px;
}

.wine-details-close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #E6E5E2;
  cursor: pointer;
  width: 60px;
  height: 60px;
}

</style>