<template>
  <div class="cycle-month-items-container">
    <CycleMonthCard
        :monthCard="monthCard"
        v-for="(monthCard, index) in props.monthsCardList.cardList"
        :key="index"
        :class="props.monthsCardList.month.french.toLowerCase()"
        :monthIndex="index"
        @button-click="handleButtonClick"
    />
  </div>
</template>

<script setup>

import {defineEmits, defineProps} from "vue";
import CycleMonthCard from "@/components/cycle/CycleMonthCard.vue";

const props = defineProps({
  monthsCardList: Object
});

const emit = defineEmits(['button-click']);
const handleButtonClick = (data) => {
  emit('button-click', data);
};
</script>

<style scoped>

.cycle-month-items-container {
  display: flex;
  justify-content: center;
  gap: 10px;
}

</style>