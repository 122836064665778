<template>
  <div
      class="container-inner"
      :class="[type]"
  >
    <VinificationTimeline
        :title="getTitleTranslations()[type][languageStore.lang]"
        :wineStepList="wineStepList"
    />
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import VinificationTimeline from "@/components/vinification-timeline/VinificationTimeline.vue";

import {languageStore} from "@/stores/languageStore";

const route = useRoute();

const { type } = route.params;
// const timelineTitle = type === 'red' ? 'Vinification du vin rouge' : 'Vinification du vin blanc';
const wineStepList = ref([]);

const getTitleTranslations = () => {
  return {
    "white": {
      "french": "Vinifinication du vin blanc",
      "english": "Vinification of white wine"
    },
    "red": {
      "french": "Vinifinication du vin rouge",
      "english": "Vinification of red wine"
    }
  };
};

import(`@/data/vinification-steps/${type}-wines.json`).then(module => {
  wineStepList.value = module.default;
});

</script>

<style scoped>

.container-inner {
  overflow: hidden;
}

</style>