<template>

  <div class="modale-container">
    <swiper
        :modules="[Pagination]"
        :slides-per-view="1"
            @swiper="onSwiper"
            :loop="true"
            navigation
            :pagination="{ clickable: true }"
            class="modale-imgs-container modale-element-container"
    >
      <swiper-slide v-for="(image, index) in props.detailCard.imageList" :key="index">
        <div class="item-container-slide">
          <img :src="getImageSrc(image.imgSrc)">
          <span v-if="image.text" class="item-text">{{ image.text[languageStore.lang] }}</span>
        </div>
      </swiper-slide>
    </swiper>
    <div class="modale-content-container modale-element-container">
      <div class="modale-close-btn-container" @click="handleCloseBtnClick">
        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
          <g transform="translate(-1480 -330)">
            <rect width="60" height="60" rx="30" transform="translate(1480 330)" fill="#f8f7f4"/>
            <g transform="translate(1510.51 333.348) rotate(45)">
              <path d="M7,4V21.692" transform="translate(11.5 5.654)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <path d="M4,7H21.692" transform="translate(5.654 11.5)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            </g>
          </g>
        </svg>
      </div>
      <div class="modale-content-inner">
        <h2 class="modale-content-title">{{ props.detailCard.title[languageStore.lang] }}</h2>
        <p class="modale-content-description" v-html="props.detailCard.description[languageStore.lang]"></p>
      </div>
    </div>
  </div>

</template>

<script setup>

import { Swiper, SwiperSlide  } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import {defineEmits, defineProps} from "vue";
import {languageStore} from "@/stores/languageStore";

const props = defineProps({
  detailCard: Object
});

const onSwiper = (swiper) => {
  console.log('Swiper instance:', swiper);
};

// on btn click
const emit = defineEmits(['close-button-click']);
const handleCloseBtnClick = () => {
  emit('close-button-click');
};

const getImageSrc = (imageSrc) => {
  if (imageSrc) {
    return require(`@/assets/img/cycle/months/${imageSrc}`);
  } else {
    return '';
  }
};

</script>

<style scoped>

.modale-container {
  display: flex;
  width: 1200px;
  margin: auto;
}

.modale-element-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  width: 600px;
}

.modale-content-inner {
  width: 400px;
}

.modale-content-title {
  font-size: 27px;
  font-weight: 600;
  text-align: center;
}

.modale-content-description {
  font-size: 16px;
  color: #8F8D88;
  line-height: 25px;
}

.modale-close-btn-container {
  position: absolute;
  top: 15px;
  right: 15px;
}

.item-container-slide {
  position: relative;
}

.item-container-slide .item-text {
  position: absolute;
  bottom: 15px;
  left: 30px;
  right: 30px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 40px;
  text-align: center;
  border-radius: 5px;
  padding: 10px;
}

</style>