<template>
  <div @click="playVideo" v-show="props.visible" class="svg-play-container">
    <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72" class="svg-play-btn">
      <g transform="translate(-572 -442)">
        <circle cx="36" cy="36" r="36" transform="translate(572 442)" fill="#2a2721"/>
        <path d="M13.4,4.558a3,3,0,0,1,5.209,0l10.83,18.953A3,3,0,0,1,26.83,28H5.17a3,3,0,0,1-2.6-4.488Z" transform="translate(626 462) rotate(90)" fill="#fff"/>
      </g>
    </svg>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';

const props = defineProps({
  visible: Boolean
});

const emit = defineEmits(['click']);

function playVideo() {
  emit('click');
}
</script>

<style scoped>

.svg-play-container {
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

svg circle {
  opacity: 0.75;
}

</style>
