<template>
  <main>
    <component :is="currentHeader"></component>
    <div class="wrapper">
      <router-view />
    </div>
  </main>
</template>

<script setup>
import HomeHeader from "@/components/header/HomeHeader.vue";
import DefaultHeader from "@/components/header/DefaultHeader.vue";
import { useRoute } from 'vue-router';

const route = useRoute();

const currentHeader = route.path === '/' ? HomeHeader : DefaultHeader;
</script>


