import { reactive } from 'vue';

export const languageStore = reactive({
	lang: localStorage.getItem('language') || 'french',
	toggleLang() {
		this.lang = this.lang === 'french' ? 'english' : 'french';
		localStorage.setItem('language', this.lang);
	},
	setLang(lang) {
		this.lang = lang;
		localStorage.setItem('language', this.lang);
	}
});