<template>
  <a href="/">
    <img :src="logo" alt="Main Logo">
  </a>
</template>

<script setup>
import { ref } from 'vue';
import mainLogoHeader from '@/assets/img/header/main-logo.svg';

const logo = ref(mainLogoHeader);

</script>